import { Flex, FlexProps } from '@chakra-ui/react';

type Props = FlexProps & {
  children: React.ReactNode;
};

export const InputsContainer = ({ children, ...props }: Props) => {
  return (
    <Flex
      bg="#fff"
      boxShadow="0px 2px 5px rgba(115, 137, 169, 0.25)"
      borderRadius="1.9rem"
      // w="25rem"
      p="2.5rem"
      flexDir="column"
      {...props}
    >
      {children}
    </Flex>
  );
};
