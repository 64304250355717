import { Flex, FlexProps, useBreakpointValue } from '@chakra-ui/react';

type Props = FlexProps & {
  children: React.ReactNode;
};

export const MainContent = ({ children, ...props }: Props) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  return (
    <Flex
      p={isWideVersion ? '3.1rem 7.75rem' : '3.1rem 0rem'}
      mb=".8rem"
      flexDir={isWideVersion ? 'row' : 'column'}
      flex="1"
      {...props}
    >
      {children}
    </Flex>
  );
};
