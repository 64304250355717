import { Select, SelectProps } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ForwardedRef } from 'react';
import { BaseProps, FormControl } from './FormControl';

export type SelectControlProps = BaseProps & { selectProps?: SelectProps };

// eslint-disable-next-line react/display-name
export const FormSelect: React.FC<SelectControlProps> = React.forwardRef(
  (props: SelectControlProps, ref: ForwardedRef<HTMLSelectElement>) => {
    const { name, label, children, selectProps, ...rest } = props;

    const [field] = useField(name);

    return (
      <FormControl name={name} label={label} {...rest} mb="1.2rem">
        <Select
          {...field}
          id={name}
          {...selectProps}
          variant="flushed"
          borderColor="gray.800"
          color="black"
          mb="1rem"
          fontSize="1rem"
        >
          {children}
        </Select>
      </FormControl>
    );
  }
);
