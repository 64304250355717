export const cnpj = [
  /[0-9]/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

export const cpf = [
  /[0-9]/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/
];

export const cpfCnpj = [
  /[0-9]/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/
];

export const postalCode = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/
];

export const phone = [
  '(',
  /[0-9]/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const oneDecimal = [/[\d]/, /[,.]+/, /\d/, /\d/];

export const threeDecimal = [
  /[\d]/,
  /[\d,.]+/,
  /[\d,.]+/,
  /[\d,.]+/,
  /\d/,
  /\d/
];

export const currency = [
  /[1-9]/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.'
];

// export const currency = (value: string) => {
//   value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

//   const options = { minimumFractionDigits: 2 };

//   const result = new Intl.NumberFormat('pt-BR', options).format(
//     parseFloat(value) / 100
//   );

//   return 'R$ ' + result;
// };
