/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { CheckboxSingleControl } from 'formik-chakra-ui';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../../../components/actions/CustomButton';
import { FormInput } from '../../../../components/fields/FormInput';
import { FormSelect } from '../../../../components/fields/FormSelect';
import { FooterContainer } from '../../../../components/layouts/FooterContainer';
import { InputsContainer } from '../../../../components/layouts/InputsContainer';
import { MainContent } from '../../../../components/layouts/MainContent';
import { ErrorFocus } from '../../../../components/widgets/ErrorFocus';
import { StepTimeLine } from '../../../../components/widgets/StepTimeline/StepTimeLine';
import { StepTimeLine2 } from '../../../../components/widgets/StepTimeline2/StepTimeLine';
import CnpjFormContext from '../../../../contexts/CnpjFormContext';
import { api } from '../../../../services/api';
import { ThirdFormType } from '../../../../types/formTypesCnpj';
import { thirdStepValidation } from '../Validations/CnpjForm';
import { banks } from '../Mocks';
import axios from 'axios';
import { CustomModal } from '../../../../components/widgets/CustomModal';

export const ThirdForm = (): JSX.Element => {
  const {
    stepState,
    setStepState,
    onboardingId,
    setOnboardingId,
    secondStepState,
    thirdStepState,
    setThirdStepState
  } = useContext(CnpjFormContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modal, setModal] = useState({
    title: '',
    content: ''
  });

  const {
    bank,
    agency,
    account,
    digit,
    type,
    acceptTerm,
    acceptDeclaration,
    acceptAuthorization
  } = thirdStepState;

  const [loading, setLoading] = useState(false);

  const isEmpty = (values: ThirdFormType) => {
    return (
      !Object.values(values) ||
      (Object.values(values).every((v) => !v) &&
        Object.getOwnPropertySymbols(values).length === 0 &&
        !acceptTerm &&
        !acceptDeclaration &&
        !acceptAuthorization)
    );
  };

  const hasErrors = (values: ThirdFormType, errors: object) => {
    return Object.keys(errors).length > 0 || isEmpty(values);
  };

  const handleSubmit = async (values: ThirdFormType) => {
    setLoading(true);

    try {
      // console.log({
      //   onboardingId,
      //   experience: parseInt(secondStepState.experience),
      //   workPlace: parseInt(secondStepState.workPlace),
      //   employees: parseInt(secondStepState.employees),
      //   insurers: [
      //     secondStepState.firstInsurer,
      //     secondStepState.secondInsurer,
      //     secondStepState.thirdInsurer,
      //     secondStepState.otherInsurer
      //   ],
      //   annualProduction: {
      //     health: parseInt(secondStepState.healthValue),
      //     dentistry: parseInt(secondStepState.dentistryValue),
      //     lifeInsurance: parseInt(secondStepState.lifeInsuranceValue),
      //     travelInsurance: parseInt(secondStepState.travelInsuranceValue),
      //     privateInsurance: parseInt(secondStepState.privateInsuranceValue),
      //     investments: parseInt(secondStepState.investmentsValue)
      //   },
      //   bank: values.bank,
      //   agency: values.agency,
      //   agencyDigit: values.agencyDigit,
      //   account: values.account,
      //   digit: values.digit,
      //   type: values.type,
      //   accept1: values.acceptTerm,
      //   accept2: values.acceptDeclaration,
      //   accept3: values.acceptAuthorization
      // });

      await sendTerms('termo-adesao', '/docs/termoAdesao.pdf');

      await sendTerms('declaracao-pj', '/docs/declaracaoPJ.pdf');

      await api.post('/signup/cnpj/second-step', {
        onboardingId,
        experience: parseInt(secondStepState.experience),
        workPlace: parseInt(secondStepState.workPlace),
        employees: parseInt(secondStepState.employees),
        insurers: [
          secondStepState.firstInsurer,
          secondStepState.secondInsurer,
          secondStepState.thirdInsurer,
          secondStepState.otherInsurer
        ],
        annualProduction: {
          health: parseInt(secondStepState.healthValue),
          dentistry: parseInt(secondStepState.dentistryValue),
          lifeInsurance: parseInt(secondStepState.lifeInsuranceValue),
          travelInsurance: parseInt(secondStepState.travelInsuranceValue),
          privateInsurance: parseInt(secondStepState.privateInsuranceValue),
          investments: parseInt(secondStepState.investmentsValue)
        },
        bank: values.bank,
        agency: values.agency,
        agencyDigit: values.agencyDigit,
        account: values.account,
        digit: values.digit,
        type: values.type,
        accept1: values.acceptTerm,
        accept2: values.acceptDeclaration,
        accept3: values.acceptAuthorization
      });

      const state = {
        bank: values.bank,
        agency: values.agency,
        agencyDigit: values.agencyDigit,
        account: values.account,
        digit: values.digit,
        type: values.type,
        acceptTerm: values.acceptTerm,
        acceptDeclaration: values.acceptDeclaration,
        acceptAuthorization: values.acceptAuthorization
      };

      setThirdStepState(state);

      setStepState(4);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 429) {
        setModal({
          title: 'Ocorreu um erro',
          content:
            'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
        });

        onOpen();
      }
    }
  };

  const sendTerms = async (filename: string, filepath: string) => {
    const file = await srcToFile(filename, filepath);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async () => {
      if (reader.result) {
        try {
          await api.post('/signup/cnpj/salesforce-upload', {
            parentId: onboardingId,
            Name: `${filename}.pdf`,
            Body: reader.result
              .toString()
              .substring(reader.result.toString().indexOf(',') + 1)
          });

          // console.log({
          //   parentId: onboardingId,
          //   Name: `${filename}.pdf`,
          //   Body: reader.result
          //     .toString()
          //     .substring(reader.result.toString().indexOf(',') + 1)
          // });
        } catch (error) {
          console.log(error);
        }
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const srcToFile = async (fileName: string, filepath: string) => {
    const response = await axios.get(filepath, {
      responseType: 'blob'
    });
    const mimeType = response.headers['content-type'];
    return new File([response.data], fileName, { type: mimeType });
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={thirdStepState}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={thirdStepValidation}
      >
        {({ handleSubmit, values, errors }): JSX.Element => (
          <Flex
            flexDir="column"
            as="form"
            flexWrap="wrap"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={handleSubmit as any}
          >
            <ErrorFocus />
            <MainContent>
              {isWideVersion && <StepTimeLine2 step={stepState} />}
              <Flex
                w="100%"
                align="center"
                flexDir="column"
                flex=".85"
                mt={isWideVersion ? '0' : '5rem'}
              >
                <Stack mb="2.5rem" spacing="0.8rem" w="25rem">
                  <Text fontSize="1.2rem">
                    Por favor, informe seus dados bancários da conta de Pessoa
                    Jurídica para crédito de comissão.
                  </Text>
                  <Text fontSize=".8rem">* Campos obrigatórios</Text>
                </Stack>
                <Stack spacing="2rem">
                  <InputsContainer w="25rem">
                    <FormSelect
                      name="bank"
                      label="Banco *"
                      selectProps={{ placeholder: 'Selecione' }}
                    >
                      {banks.map((bank) => (
                        <option
                          key={bank.id}
                          value={bank.id + ' - ' + bank.name}
                        >
                          {bank.id + ' - ' + bank.name}
                        </option>
                      ))}
                    </FormSelect>
                    <Flex justify="space-between" w="100%">
                      <FormInput
                        name="agency"
                        label="Agência *"
                        mr=".6rem"
                        w="8rem"
                        maxLength={4}
                      />
                      <FormInput
                        name="agencyDigit"
                        w="5rem"
                        label="Dígito Agência"
                        maxLength={1}
                      />
                    </Flex>
                    <Flex justify="space-between" w="100%">
                      <FormInput
                        name="account"
                        label="Conta Pessoa Jurídica *"
                        mr=".6rem"
                        w="14rem"
                      />
                      <FormInput
                        name="digit"
                        label="Dígito *"
                        maxLength={1}
                        w="5rem"
                      />
                    </Flex>

                    <FormSelect
                      name="type"
                      label="Tipo de conta *"
                      selectProps={{ placeholder: 'Selecione' }}
                    >
                      <option value="1">Conta Corrente</option>
                      <option value="2">Conta Poupança</option>
                    </FormSelect>
                  </InputsContainer>
                </Stack>
                <Stack
                  w={{ base: '20rem', md: '40rem', lg: '40rem' }}
                  mt="2rem"
                  spacing="1rem"
                >
                  <HStack>
                    <CheckboxSingleControl
                      name="acceptTerm"
                      w="auto"
                      checkBoxProps={{
                        borderColor: '#0B234C',
                        size: 'lg'
                      }}
                    />

                    <Text fontSize="1rem">
                      Para cadastrar a corretora no Portal do Corretor da
                      SulAmérica, como representante legal em sociedade, li e
                      concordo com o seu{' '}
                      <Link
                        style={{ textDecoration: 'underline' }}
                        rel="noopener noreferrer"
                        target="_blank"
                        to="/docs/termoAdesao.pdf"
                      >
                        Termo de Adesão.
                      </Link>
                    </Text>
                  </HStack>
                  <HStack>
                    <CheckboxSingleControl
                      name="acceptDeclaration"
                      w="auto"
                      checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                    />
                    <Text fontSize="1rem">
                      Declaro que li e concordo com os{' '}
                      <Link
                        style={{ textDecoration: 'underline' }}
                        rel="noopener noreferrer"
                        target="_blank"
                        to="/docs/declaracaoPJ.pdf"
                      >
                        Termos e Condições de Ciência e Declaração
                      </Link>{' '}
                      descritos neste instrumento.
                    </Text>
                  </HStack>
                  <HStack>
                    <CheckboxSingleControl
                      name="acceptAuthorization"
                      w="auto"
                      checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                    />
                    <Text fontSize="1rem" ml=".8rem">
                      Faço referência a minha autorização para crédito de
                      comissão em minha Conta Corrente ou Poupança informada
                      acima. É importante manter esses dados atualizados para
                      que não haja problemas com pagamento de comissão. Declaro
                      a autorização do crédito das comissões em conta conjunta,
                      se aplicável, seja ela Corrente ou Poupança, conforme os
                      dados digitados e verificados acima.
                    </Text>
                  </HStack>
                </Stack>
              </Flex>
            </MainContent>
            <FooterContainer>
              <HStack
                h="100%"
                spacing={{ base: '0', md: '10', lg: '10' }}
                flexWrap="wrap"
                justify="center"
                textAlign="center"
              >
                <CustomButton variant="outline" onClick={() => setStepState(2)}>
                  VOLTAR
                </CustomButton>
                <CustomButton type="submit" isLoading={loading}>
                  AVANÇAR
                </CustomButton>
              </HStack>
            </FooterContainer>
          </Flex>
        )}
      </Formik>

      <CustomModal
        isOpen={isOpen}
        title={modal.title}
        onClose={onClose}
        content={<Text>{modal.content}</Text>}
      />
    </>
  );
};
