import { Flex } from '@chakra-ui/react';

type Props = {
  children: React.ReactNode;
};

export const FooterContainer = ({ children }: Props) => {
  return (
    <Flex bg="#fff" w="100%" h="8rem" justify="center" align="center">
      {children}
    </Flex>
  );
};
