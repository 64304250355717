import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

export const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'orange'
  }),
  {
    colors: {
      gray: {
        '100': '#E5E5E5'
      },
      orange: {
        '500': '#FF5000'
      }
    },
    fonts: {
      body: 'Roboto',
      heading: 'Roboto'
    },
    styles: {
      global: {
        body: {
          bg: 'gray.100',
          color: '#000'
        },
        a: {
          _hover: {
            textDecoration: 'none !important'
          }
        }
      }
    }
  }
);
