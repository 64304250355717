import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';
import {
  FirstFormType,
  FourthFormType,
  SecondFormType,
  ThirdFormType
} from '../types/formTypesCpf';

type CpfFormContextProps = {
  stepState: number;
  setStepState: Dispatch<SetStateAction<number>>;

  onboardingId: string;
  setOnboardingId: Dispatch<SetStateAction<string>>;

  firstStepState: FirstFormType;
  setFirstStepState: Dispatch<SetStateAction<FirstFormType>>;

  secondStepState: SecondFormType;
  setSecondStepState: Dispatch<SetStateAction<SecondFormType>>;

  thirdStepState: ThirdFormType;
  setThirdStepState: Dispatch<SetStateAction<ThirdFormType>>;

  fourthStepState: FourthFormType;
  setFourthStepState: Dispatch<SetStateAction<FourthFormType>>;
};

const initialValues = {
  stepState: 1,
  onboardingId: '',
  firstStepState: {
    cpf: '',
    name: '',
    birthday: '',
    susep: '',
    statusCpf: '',
    impededCpf: false,
    pepCpf: false,
    firstCnae: '',
    secondCnaes: [''],
    postalCode: '',
    address: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    isMailAddress: true,
    mailPostalCode: '',
    mailAddress: '',
    mailNumber: '',
    mailDistrict: '',
    mailCity: '',
    mailState: '',
    mailComplement: '',
    phone1: '',
    frame1: '',
    phone2: '',
    frame2: '',
    email: '',
    isAdvice: '',
    health: false,
    dentistry: false,
    lifeInsurance: false,
    travelInsurance: false,
    privateInsurance: false,
    investments: false,
    isSimplesNacional: '',
    isPartner: '',
    cnpjPartner: '',
    cnpjPartnerSusep: '',
    cnpjPartnerSusepStatus: '',
    cnpjPartnerCnpjStatus: '',
    cnpjPartnerProducts: '',
    cnpjPartnerImpeded: false
  },
  secondStepState: {
    experience: '',
    workPlace: '',
    estimatedIncome: '',
    exactIncome: '',
    healthValue: '',
    dentistryValue: '',
    lifeInsuranceValue: '',
    travelInsuranceValue: '',
    privateInsuranceValue: '',
    investmentsValue: '',
    firstInsurer: '',
    secondInsurer: '',
    thirdInsurer: '',
    otherInsurer: ''
  },

  thirdStepState: {
    bank: '',
    agency: '',
    agencyDigit: '',
    account: '',
    digit: '',
    type: '',
    acceptTerm: false,
    acceptDeclaration: false,
    acceptAuthorization: false
  },

  fourthStepState: {
    residence: '',
    cpf: '',
    pisPasepInss: '',
    municipalRegister: '',
    isAdvice: '',
    adviceLetter: '',
    eSocial: '',
    accept: false
  },

  setFirstStepState: () => {
    ('');
  },

  setSecondStepState: () => {
    ('');
  },

  setThirdStepState: () => {
    ('');
  },

  setFourthStepState: () => {
    ('');
  },

  setStepState: () => {
    ('');
  },

  setOnboardingId: () => {
    ('');
  }
};

const CpfFormContext = createContext<CpfFormContextProps>(initialValues);

const CpfFormContextProvider: FC = ({ children }) => {
  const [stepState, setStepState] = useState(initialValues.stepState);
  const [onboardingId, setOnboardingId] = useState(initialValues.onboardingId);

  const [firstStepState, setFirstStepState] = useState(
    initialValues.firstStepState
  );

  const [secondStepState, setSecondStepState] = useState(
    initialValues.secondStepState
  );

  const [thirdStepState, setThirdStepState] = useState(
    initialValues.thirdStepState
  );

  const [fourthStepState, setFourthStepState] = useState(
    initialValues.fourthStepState
  );

  return (
    <CpfFormContext.Provider
      value={{
        stepState,
        onboardingId,
        setOnboardingId,
        setStepState,
        firstStepState,
        setFirstStepState,
        secondStepState,
        setSecondStepState,
        thirdStepState,
        setThirdStepState,
        fourthStepState,
        setFourthStepState
      }}
    >
      {children}
    </CpfFormContext.Provider>
  );
};
export { CpfFormContextProvider };
export default CpfFormContext;
