import { Box, StackProps } from '@chakra-ui/react';
import { StepItem } from './StepItem';

type Props = StackProps & {
  step: number;
};

export const StepTimeLine2 = ({ step }: Props): JSX.Element => {
  return (
    <Box>
      <StepItem
        description="Dados Cadastrais"
        complete={step > 1}
        isStep={step === 1}
        step={'1'}
      />
      <StepItem
        description="Pesquisa"
        complete={step > 2}
        isStep={step === 2}
        step={'2'}
      />
      <StepItem
        description="Dados Bancários"
        complete={step > 3}
        isStep={step === 3}
        step={'3'}
      />
      <StepItem
        description="Anexos"
        last
        complete={step > 4}
        isStep={step === 4}
        step={'4'}
      />
    </Box>
  );
};
