/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Flex,
  FormLabel,
  HStack,
  Link,
  Radio,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import { Formik } from 'formik';
import {
  CheckboxSingleControl,
  RadioGroupControl,
  SwitchControl
} from 'formik-chakra-ui';
import { useContext, useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

import { CustomButton } from '../../../../components/actions/CustomButton';
import { FormInput } from '../../../../components/fields/FormInput';
import { FormMaskedInput } from '../../../../components/fields/FormMaskedInput';
import { FormSelect } from '../../../../components/fields/FormSelect';
import { FooterContainer } from '../../../../components/layouts/FooterContainer';
import { InputsContainer } from '../../../../components/layouts/InputsContainer';
import { MainContent } from '../../../../components/layouts/MainContent';
import { CustomModal } from '../../../../components/widgets/CustomModal';
import { ErrorFocus } from '../../../../components/widgets/ErrorFocus';
import { StepTimeLine2 } from '../../../../components/widgets/StepTimeline2/StepTimeLine';
import CpfFormContext from '../../../../contexts/CpfFormContext';
import { api } from '../../../../services/api';
import { FirstFormType } from '../../../../types/formTypesCpf';
import { isCpfValid } from '../../../../utils/checkers/checkCpf';
import { states } from '../../../../utils/statics';
import { firstStepValidation } from '../Validations/CpfForm';

export const FirstForm = (): JSX.Element => {
  const {
    stepState,
    setStepState,
    onboardingId,
    setOnboardingId,
    firstStepState,
    setFirstStepState
  } = useContext(CpfFormContext);

  const {
    isMailAddress: isMailAddressState,
    statusCpf,
    impededCpf,
    pepCpf,
    firstCnae,
    secondCnaes,
    statusSusep,
    cnpjPartnerImpeded,
    cnpjPartnerSusep,
    cnpjPartnerSusepStatus,
    cnpjPartnerCnpjStatus,
    cnpjPartnerProducts
  } = firstStepState;

  const [loading, setLoading] = useState(false);
  const [loadingMailPostalCode, setLoadingMailPostalCode] = useState(false);
  const [loadingCpf, setLoadingCpf] = useState(false);
  const [loadingCnpjPartner, setLoadingCnpjPartner] = useState(false);

  const [cpfSusepError, setCpfSusepError] = useState(false);

  const [cpfStatus, setCpfStatus] = useState({
    status: statusCpf,
    impeded: impededCpf,
    pep: pepCpf,
    firstCnae: firstCnae,
    secondCnaes: secondCnaes
  });

  const [susepStatus, setSusepStatus] = useState({
    status: statusSusep?.status,
    products: statusSusep?.products
  });

  const [isMailAddress, setIsMailAddress] = useState(isMailAddressState);

  const [modal, setModal] = useState({
    title: '',
    content: ''
  });

  const {
    isOpen: isOpenCpfAlert,
    onOpen: onOpenCpfAlert,
    onClose: onCloseCpfAlert
  } = useDisclosure();

  const {
    isOpen: isOpenError,
    onOpen: onOpenError,
    onClose: onCloseError
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const clearAutomaticData = (values: FirstFormType) => {
    values.name = '';
    values.birthday = '';
    values.postalCode = '';
    values.address = '';
    values.city = '';
    values.district = '';
    values.state = '';
    values.number = '';
    values.complement = '';
    values.susep = '';
  };

  const clearIsMailAddress = (values: FirstFormType) => {
    values.mailPostalCode = '';
    values.mailAddress = '';
    values.mailCity = '';
    values.mailDistrict = '';
    values.mailState = '';
    values.mailNumber = '';
    values.mailComplement = '';
  };

  const clearCnpjPartner = (values: FirstFormType) => {
    values.cnpjPartner = '';
    setFirstStepState({
      ...firstStepState,
      cnpjPartnerSusep: '',
      cnpjPartnerSusepStatus: '',
      cnpjPartnerProducts: '',
      cnpjPartnerImpeded: false
    });
  };

  const checkCpf = async (
    values: FirstFormType,
    setFieldError: (field: string, message: string) => void
  ) => {
    const cpf = values.cpf.replaceAll('.', '').replace('-', '');

    if (firstStepState.cpf !== values.cpf) {
      setOnboardingId('');
    }

    const isValid = isCpfValid(cpf);

    if (cpf.length === 11) {
      clearAutomaticData(values);

      setLoadingCpf(true);

      if (!isValid) {
        setModal({
          title: 'Atenção',
          content: 'Cpf inválido, favor verificar.'
        });

        clearAutomaticData(values);

        onOpen();

        setLoadingCpf(false);

        return;
      }

      try {
        const { data: produtorData } = await api.get(
          `produtor/verify/${cpf}?tipo_consulta=impedido,pep`
        );

        if (produtorData.data.alreadyRegistered) {
          clearAutomaticData(values);

          onOpenCpfAlert();

          setLoadingCpf(false);

          return;
        }

        const { data: transunionData } = await api.get(
          `/cpfCnpj/transunion/${cpf}`
        );

        const transunionStatus = transunionData.message
          ? transunionData.message
          : transunionData.SITUACAO_SRF?.DS_SRF_PF;

        if (transunionStatus !== 'REGULAR') {
          setModal({
            title: 'CPF inconsistente',
            content:
              'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
          });

          clearAutomaticData(values);

          onOpen();

          setLoadingCpf(false);

          return;
        }

        const { data: cpfValidate } = await api.get(`/cpfCnpj/${cpf}`);

        if (
          !isValid ||
          cpfValidate === 'CPF inválido' ||
          transunionStatus !== 'REGULAR'
        ) {
          setFieldError('cpf', 'CPF inativo/inválido, favor verificar.');

          setLoadingCpf(false);

          return;
        }

        // const cdCnae = transunionData.message
        //   ? transunionData.message
        //   : transunionData.SITUACAO_SRF?.CD_CNAE;

        // const cdSecondCnaes = transunionData.message
        //   ? transunionData.message
        //   : transunionData.CNAES_SECUNDARIOS.length > 0
        //   ? transunionData.CNAES_SECUNDARIOS.map(
        //       (cnae: {
        //         CD_CNAE_SECUNDARIO: string;
        //         DS_CNAE_SECUNDARIO: string;
        //       }) => cnae.CD_CNAE_SECUNDARIO
        //     )
        //   : [''];

        setCpfStatus({
          status: transunionStatus,
          impeded: produtorData.data ? produtorData.data.impedido : false,
          pep: produtorData.data ? produtorData.data.pep : false,
          firstCnae: '',
          secondCnaes: ['']
        });

        // if (
        //   (await isValidCnae(cdCnae, transunionData.CNAES_SECUNDARIOS)) ===
        //   false
        // ) {
        //   setModal({
        //     title: 'Atividade de corretagem de seguros não autorizada',
        //     content:
        //       'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
        //   });

        //   clearAutomaticData(values);

        //   onOpen();

        //   setLoadingCpf(false);

        //   return;
        // }

        try {
          const { data: susepData } = await api.get(`/cpfCnpj/susep/${cpf}`, {
            timeout: 7000
          });

          if (susepData.retorno?.registros?.length) {
            const mandatoryProducts = [
              'Seguros de Pessoas',
              'Planos de Previdência Complementar'
            ];

            const susepProducts = susepData.retorno?.registros[0]?.produtos;

            const includesMandatoryProducts = susepProducts
              ? mandatoryProducts.every((item) => susepProducts?.includes(item))
              : false;

            if (!includesMandatoryProducts) {
              setModal({
                title:
                  'Você deve ter os produtos Seguro de Vida e Planos de Previdência autorizados pela SUSEP',
                content:
                  'Por favor regularize a sua situação junto aos órgãos competentes e retorne para o processo de cadastro.'
              });

              clearAutomaticData(values);

              onOpen();

              setLoadingCpf(false);

              return;
            }
          } else if (susepData.retorno?.registros[0]?.situacao !== 'Ativo') {
            setModal({
              title: 'Habilitação SUSEP inativa',
              content:
                'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
            });

            clearAutomaticData(values);

            onOpen();

            setLoadingCpf(false);

            return;
          }

          setSusepStatus({
            status: susepData.retorno?.registros[0]?.situacao,
            products: susepData.retorno?.registros[0]?.produtos
          });

          values.susep = susepData.retorno?.registros[0]?.protocolo;
        } catch (error: any) {
          console.log(error);
          setCpfSusepError(true);

          if (error?.response?.status === 429) {
            setModal({
              title: 'Ocorreu um erro',
              content:
                'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
            });

            onOpen();
          }
        }

        values.name = transunionData.DADOS_PRINCIPAIS.NM_COMPLETO;
        values.birthday = formatTransunionDate(
          transunionData.DADOS_PRINCIPAIS.DT_NASCIMENTO
        );
        values.postalCode = transunionData.ENDERECOS[0].NR_CEP;
        values.address = transunionData.ENDERECOS[0].NM_LOGRADOURO;
        values.city = transunionData.ENDERECOS[0].NM_CIDADE;
        values.district = transunionData.ENDERECOS[0].NM_BAIRRO;
        values.state = transunionData.ENDERECOS[0].SG_ESTADO;
        values.number = transunionData.ENDERECOS[0].NR_LOGRADOURO;
        values.complement = transunionData.ENDERECOS[0].DS_COMPLEMENTO;
      } catch (error: any) {
        setFieldError(
          'cpf',
          'Ocorreu algum erro. Tente novamente em instantes, por favor.'
        );

        setLoadingCpf(false);

        clearAutomaticData(values);

        if (error?.response?.status === 429) {
          setModal({
            title: 'Ocorreu um erro',
            content:
              'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
          });

          onOpen();
        }

        return;
      }
    }

    setLoadingCpf(false);

    return;
  };

  const formatTransunionDate = (date: string) => {
    const timeStampDate = parseInt(date.split('(')[1].split('-0300')[0]);

    const formattedDate = new Date(timeStampDate).toLocaleDateString();

    return formattedDate;
  };

  const checkCnpjPartner = async (
    values: FirstFormType,
    setFieldError: (field: string, message: string) => void
  ) => {
    const cnpjPartner = values.cnpjPartner
      .replaceAll('.', '')
      .replace('-', '')
      .replace('/', '');

    if (cnpjPartner.length === 14) {
      setLoadingCnpjPartner(true);

      try {
        const { data: produtorData } = await api.get(
          `produtor/verify/${cnpjPartner}?tipo_consulta=impedido`
        );

        try {
          const { data: susepData } = await api.get(
            `/cpfCnpj/susep/${cnpjPartner}`,
            {
              timeout: 7000
            }
          );

          const { data: transunionData } = await api.get(
            `/cpfCnpj/transunion/${cnpjPartner}`
          );

          const transunionStatus = transunionData.message
            ? transunionData.message
            : transunionData.SITUACAO_SRF?.DS_SITUACAO;

          setFirstStepState({
            ...firstStepState,
            cnpjPartnerSusep: susepData.retorno?.registros[0]?.protocolo,
            cnpjPartnerSusepStatus: susepData.retorno?.registros[0]?.situacao,
            cnpjPartnerCnpjStatus: transunionStatus,
            cnpjPartnerImpeded: produtorData.data.impedido,
            cnpjPartnerProducts: susepData.retorno?.registros[0]?.produtos
          });
        } catch (error: any) {
          console.log(error);

          setLoadingCnpjPartner(false);

          if (error?.response?.status === 429) {
            setModal({
              title: 'Ocorreu um erro',
              content:
                'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
            });

            onOpen();
          }
        }

        setLoadingCnpjPartner(false);
      } catch (error: any) {
        setFieldError('cnpjPartner', 'Ocorreu um erro. Tente novamente');

        setLoadingCnpjPartner(false);

        if (error?.response?.status === 429) {
          setModal({
            title: 'Ocorreu um erro',
            content:
              'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
          });

          onOpen();
        }
      }
    }
  };

  const checkMailPostalCode = async (values: FirstFormType) => {
    const postalCode = values.mailPostalCode;

    if (postalCode.length === 9) {
      try {
        values.mailAddress = '';
        values.mailDistrict = '';
        values.mailState = '';
        values.mailCity = '';

        setLoadingMailPostalCode(true);

        const response = await api.get<any>(`/consulta_cep/${postalCode}`);

        values.mailAddress = response.data.logradouro;
        values.mailDistrict = response.data.bairro;
        values.mailState = response.data.uf;
        values.mailCity = response.data.localidade;

        setLoadingMailPostalCode(false);
      } catch (error: any) {
        values.mailPostalCode = '';

        if (error?.response?.status === 429) {
          setModal({
            title: 'Ocorreu um erro',
            content:
              'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
          });

          onOpen();
        }
      }
    }
  };

  const isValidCnae = async (
    cdCnae: number,
    cnaesSecundarios: Array<{ CD_CNAE_SECUNDARIO: string }>
  ) => {
    if (cdCnae === 6622300) return true;

    if (cnaesSecundarios?.find((cnae) => cnae.CD_CNAE_SECUNDARIO === '6622300'))
      return true;

    return false;
  };

  const handleSubmit = async (values: FirstFormType) => {
    setLoading(true);

    try {
      const crm = {
        status:
          values.isAdvice === 'yes' ? '' : 'Aguardando complemento de cadastro',
        owner: values.isAdvice === 'yes' ? '' : 'Em cadastro'
      };

      // console.log({
      //   onboardingId,
      //   cpf: values.cpf.replaceAll('.', '').replace('-', ''),
      //   name: values.name,
      //   birthday: values.birthday,
      //   statusCpf: statusCpf,
      //   impeded: cpfStatus.impeded,
      //   pep: cpfStatus.pep,
      //   // firstCnae: cpfStatus.firstCnae,
      //   // secondCnaes: cpfStatus.secondCnaes,
      //   susep: {
      //     number: values.susep,
      //     status: susepStatus.status ? susepStatus.status : '',
      //     products: susepStatus.products ? susepStatus.products : ''
      //   },
      //   address: {
      //     postalCode: values.postalCode.toString(),
      //     city: values.city,
      //     district: values.district,
      //     state: values.state,
      //     street: values.address,
      //     number: values.number,
      //     complement: values.complement
      //   },
      //   isMailAddress: values.isMailAddress,
      //   mailAddress: {
      //     postalCode: values.mailPostalCode,
      //     city: values.mailCity,
      //     district: values.mailDistrict,
      //     state: values.mailState,
      //     street: values.mailAddress,
      //     number: values.mailNumber,
      //     complement: values.mailComplement
      //   },
      //   phone1: values.phone1,
      //   frame1: values.frame1,
      //   phone2: values.phone2,
      //   frame2: values.frame2,
      //   email: values.email,
      //   isAdvice: values.isAdvice === 'yes' ? true : false,
      //   isPartner: values.isPartner === 'yes' ? true : false,
      //   cnpjPartner: {
      //     cnpj: values.cnpjPartner,
      //     susep: cnpjPartnerSusep ? cnpjPartnerSusep : '',
      //     status: cnpjPartnerSusepStatus ? cnpjPartnerSusepStatus : '',
      //     products: cnpjPartnerProducts ? cnpjPartnerProducts : '',
      //     impeded: cnpjPartnerImpeded ? cnpjPartnerImpeded : false
      //   },
      //   productType: {
      //     health: values.health,
      //     dentistry: values.dentistry,
      //     lifeInsurance: values.lifeInsurance,
      //     travelInsurance: values.travelInsurance,
      //     privateInsurance: values.privateInsurance,
      //     investments: values.investments
      //   },
      //   crm
      // });

      const response = await api.post('/signup/cpf/first-step', {
        onboardingId,
        cpf: values.cpf.replaceAll('.', '').replace('-', ''),
        name: values.name,
        birthday: values.birthday,
        statusCpf: cpfStatus.status,
        impeded: cpfStatus.impeded,
        pep: cpfStatus.pep,
        // firstCnae: cpfStatus.firstCnae,
        // secondCnaes: cpfStatus.secondCnaes,
        susep: {
          number: values.susep,
          status: susepStatus.status ? susepStatus.status : '',
          products: susepStatus.products ? susepStatus.products : ''
        },
        address: {
          postalCode: values.postalCode.toString(),
          city: values.city,
          district: values.district,
          state: values.state,
          street: values.address,
          number: values.number,
          complement: values?.complement ?? ''
        },
        isMailAddress: values.isMailAddress,
        mailAddress: {
          postalCode: values.mailPostalCode,
          city: values.mailCity,
          district: values.mailDistrict,
          state: values.mailState,
          street: values.mailAddress,
          number: values.mailNumber,
          complement: values.mailComplement
        },
        phone1: values.phone1,
        frame1: values.frame1,
        phone2: values.phone2,
        frame2: values.frame2,
        email: values.email,
        isAdvice: values.isAdvice === 'yes' ? true : false,
        isPartner: values.isPartner === 'yes' ? true : false,
        cnpjPartner: {
          cnpj: values.cnpjPartner,
          susep: cnpjPartnerSusep ? cnpjPartnerSusep : '',
          statusSusep: cnpjPartnerSusepStatus ? cnpjPartnerSusepStatus : '',
          statusCnpj: cnpjPartnerCnpjStatus ? cnpjPartnerCnpjStatus : '',
          products: cnpjPartnerProducts ? cnpjPartnerProducts : '',
          impeded: cnpjPartnerImpeded ? cnpjPartnerImpeded : false
        },
        productType: {
          health: values.health,
          dentistry: values.dentistry,
          lifeInsurance: values.lifeInsurance,
          travelInsurance: values.travelInsurance,
          privateInsurance: values.privateInsurance,
          investments: values.investments
        },
        crm
      });

      if (response.data.response) {
        setModal({
          title: 'Seu cadastro está em análise',
          content: 'Por favor, aguarde retorno através do email.'
        });

        onOpen();

        setLoading(false);

        return;
      }

      setOnboardingId(response.data.corretora.Id);

      const state = {
        ...firstStepState,
        cpf: values.cpf,
        statusCpf: cpfStatus.status,
        name: values.name,
        birthday: values.birthday,
        susep: values.susep,
        impededCpf: impededCpf,
        pepCpf: pepCpf,
        // firstCnae: values.firstCnae,
        // secondCnaes: values.secondCnaes,
        postalCode: values.postalCode,
        address: values.address,
        number: values.number,
        district: values.district,
        city: values.city,
        state: values.state,
        complement: values.complement,
        isMailAddress: values.isMailAddress,
        mailPostalCode: values.mailPostalCode,
        mailAddress: values.mailAddress,
        mailNumber: values.mailNumber,
        mailDistrict: values.mailDistrict,
        mailCity: values.mailCity,
        mailState: values.mailState,
        mailComplement: values.mailComplement,
        phone1: values.phone1,
        frame1: values.frame1,
        phone2: values.phone2,
        frame2: values.frame2,
        email: values.email,
        isAdvice: values.isAdvice,
        health: values.health,
        dentistry: values.dentistry,
        lifeInsurance: values.lifeInsurance,
        travelInsurance: values.travelInsurance,
        privateInsurance: values.privateInsurance,
        investments: values.investments,
        isSimplesNacional: values.isSimplesNacional,
        isPartner: values.isPartner,
        cnpjPartner: values.cnpjPartner
      };

      setFirstStepState(state);

      setStepState(2);

      setLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 429) {
        setModal({
          title: 'Ocorreu um erro',
          content:
            'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
        });

        onOpen();
      }

      console.log(error);

      // setModal({
      //   title: 'Atenção',
      //   content:
      //     'Seus dados foram enviados para cadastro, aguarde que entraremos em contato.'
      // });

      // onOpenError();

      // await api.post('/signup/enviar-email', {
      //   onboardingId,
      //   cpf: values.cpf.replaceAll('.', '').replace('-', ''),
      //   name: values.name,
      //   birthday: values.birthday,
      //   statusCpf: cpfStatus.status,
      //   impeded: cpfStatus.impeded,
      //   pep: cpfStatus.pep,
      //   susep: {
      //     number: values.susep,
      //     status: susepStatus.status ? susepStatus.status : '',
      //     products: susepStatus.products ? susepStatus.products : ''
      //   },
      //   address: {
      //     postalCode: values.postalCode.toString(),
      //     city: values.city,
      //     district: values.district,
      //     state: values.state,
      //     street: values.address,
      //     number: values.number,
      //     complement: values.complement
      //   },
      //   isMailAddress: values.isMailAddress,
      //   mailAddress: {
      //     postalCode: values.mailPostalCode,
      //     city: values.mailCity,
      //     district: values.mailDistrict,
      //     state: values.mailState,
      //     street: values.mailAddress,
      //     number: values.mailNumber,
      //     complement: values.mailComplement
      //   },
      //   phone1: values.phone1,
      //   frame1: values.frame1,
      //   phone2: values.phone2,
      //   frame2: values.frame2,
      //   email: values.email,
      //   isAdvice: values.isAdvice === 'yes' ? true : false,
      //   isPartner: values.isPartner === 'yes' ? true : false,
      //   cnpjPartner: {
      //     cnpj: values.cnpjPartner,
      //     susep: cnpjPartnerSusep ? cnpjPartnerSusep : '',
      //     statusSusep: cnpjPartnerSusepStatus ? cnpjPartnerSusepStatus : '',
      //     statusCnpj: cnpjPartnerCnpjStatus ? cnpjPartnerCnpjStatus : '',
      //     products: cnpjPartnerProducts ? cnpjPartnerProducts : '',
      //     impeded: cnpjPartnerImpeded ? cnpjPartnerImpeded : false
      //   },
      //   productType: {
      //     health: values.health,
      //     dentistry: values.dentistry,
      //     lifeInsurance: values.lifeInsurance,
      //     travelInsurance: values.travelInsurance,
      //     privateInsurance: values.privateInsurance,
      //     investments: values.investments
      //   },
      //   crm
      // });

      setLoading(false);
    }
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={firstStepState}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={firstStepValidation}
      >
        {({ handleSubmit, values, errors, setFieldError }): JSX.Element => (
          <Flex
            flexDir="column"
            as="form"
            flexWrap="wrap"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={handleSubmit as any}
          >
            <ErrorFocus />
            <MainContent>
              {/* <StepTimeLine step={stepState} /> */}
              {isWideVersion && <StepTimeLine2 step={stepState} />}

              <Flex
                align="center"
                flexDir="column"
                alignSelf="center"
                flex=".85"
                mt={isWideVersion ? '0' : '5rem'}
              >
                <Stack mb="2.5rem" spacing="0.8rem" w="25rem">
                  <Text fontSize="1.2rem">
                    Agora, por favor, preencha os dados abaixo:
                  </Text>
                  <Text fontSize=".8rem">
                    * Todos os campos que não estiverem demarcados como
                    (Opcional) são obrigatórios o preenchimento.
                  </Text>
                  <Text fontSize=".8rem">
                    Alguns dados retornarão automaticamente de fontes públicas
                    para facilitar o seu cadastro.
                  </Text>
                </Stack>
                <Stack
                  spacing="2rem"
                  pointerEvents={
                    loadingCpf || loadingMailPostalCode || loadingCnpjPartner
                      ? 'none'
                      : 'all'
                  }
                  opacity={
                    loadingCpf || loadingMailPostalCode || loadingCnpjPartner
                      ? '0.4'
                      : '1'
                  }
                >
                  <InputsContainer w="25rem">
                    <Box>
                      <FormMaskedInput
                        label="CPF "
                        name="cpf"
                        mask="cpf"
                        value={values.cpf}
                        onChange={(e) => {
                          values.cpf = e.target.value;
                          checkCpf(values, setFieldError);
                        }}
                        loading={loadingCpf}
                      />
                      <FormInput
                        name="name"
                        // isReadOnly
                        label="Nome Completo"
                        loading={loadingCpf}
                      />
                      <FormInput
                        name="birthday"
                        // isReadOnly
                        label="Data de nascimento"
                        loading={loadingCpf}
                      />
                      <FormInput
                        name="susep"
                        // isReadOnly={!cpfSusepError}
                        label="Habilitação SUSEP "
                        loading={loadingCpf}
                      />
                    </Box>
                    <Box mb="1rem">
                      <Flex>
                        <Box mr="3.5rem" w="10rem">
                          <FormMaskedInput
                            name="phone1"
                            mask="phone"
                            label="Celular"
                          />
                        </Box>
                        {/* <Box w="6.5rem">
                          <FormInput name="frame1" label="Ramal (opcional)" />
                        </Box> */}
                      </Flex>
                      <Flex>
                        <Box mr="3.5rem" w="10rem">
                          <FormMaskedInput
                            mask="phone"
                            name="phone2"
                            label="Telefone 2 (opcional)"
                          />
                        </Box>
                        <Box w="6.5rem">
                          <FormInput
                            name="frame2"
                            label="Ramal (opcional)"
                            maxLength={4}
                          />
                        </Box>
                      </Flex>
                      <FormInput name="email" label="E-mail " />
                    </Box>
                  </InputsContainer>

                  <InputsContainer w="25rem">
                    <Box>
                      <FormInput
                        name="postalCode"
                        // isReadOnly
                        label="CEP"
                        loading={loadingCpf}
                      />

                      <FormInput
                        name="address"
                        // isReadOnly
                        label="Endereço"
                        loading={loadingCpf}
                      />
                      <Flex>
                        <Box w="50%">
                          <FormInput
                            name="number"
                            // isReadOnly
                            label="Número"
                            loading={loadingCpf}
                            mr="2.5rem"
                          />
                        </Box>
                        <Box w="50%">
                          <FormInput
                            name="complement"
                            // isReadOnly
                            label="Complemento (opcional)"
                            loading={loadingCpf}
                          />
                        </Box>
                      </Flex>
                      <FormInput
                        name="district"
                        // isReadOnly
                        label="Bairro"
                        loading={loadingCpf}
                      />
                      <FormInput
                        name="city"
                        // isReadOnly
                        label="Cidade"
                        loading={loadingCpf}
                      />
                      <FormSelect
                        name="state"
                        label="Estado *"
                        selectProps={{
                          placeholder: 'Selecione...'
                        }}
                      >
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </FormSelect>
                    </Box>

                    <Flex align="center">
                      <SwitchControl
                        name="isMailAddress"
                        switchProps={{ size: 'lg' }}
                        onChange={() => {
                          setIsMailAddress(values.isMailAddress);
                          if (values.isMailAddress) {
                            clearIsMailAddress(values);
                          }
                        }}
                        mr=".8rem"
                      />
                      <Text fontSize=".9rem">
                        Desejo utilizar esse mesmo endereço para correspondência
                      </Text>
                    </Flex>

                    {!values.isMailAddress && (
                      <Box mt="2rem">
                        <Text fontWeight="bold" mb="0.3rem">
                          Endereço de Correspondência
                        </Text>
                        <FormMaskedInput
                          mask="postalCode"
                          name="mailPostalCode"
                          value={values.mailPostalCode}
                          onChange={(e) => {
                            values.mailPostalCode = e.target.value;
                            checkMailPostalCode(values);
                          }}
                          label="CEP"
                        />
                        <FormInput name="mailAddress" label="Endereço" />
                        <Flex>
                          <FormInput
                            name="mailNumber"
                            label="Número"
                            mr="2.5rem"
                          />
                          <Box>
                            <FormInput
                              name="mailComplement"
                              label="Complemento"
                            />
                          </Box>
                        </Flex>
                        <FormInput
                          name="mailDistrict"
                          loading={loadingMailPostalCode}
                          label="Bairro"
                        />
                        <FormInput
                          name="mailCity"
                          loading={loadingMailPostalCode}
                          label="Cidade"
                        />
                        <FormSelect
                          name="mailState"
                          label="Estado *"
                          selectProps={{
                            placeholder: 'Selecione...'
                          }}
                        >
                          {states.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </FormSelect>
                      </Box>
                    )}
                  </InputsContainer>

                  <InputsContainer w="25rem">
                    <Flex mb="2.5rem">
                      <Box>
                        <FormLabel fontWeight="bold">
                          Você trabalha em parceria com Assessoria de Seguros?
                        </FormLabel>
                        <RadioGroupControl name="isAdvice" mb="1rem">
                          <Flex justify="space-between" w="75%">
                            <Radio value="yes">Sim</Radio>

                            <Radio value="no">Não</Radio>
                          </Flex>
                        </RadioGroupControl>
                      </Box>
                      <Box>
                        <Tooltip
                          shouldWrapChildren
                          bg="#1F1E1F"
                          color="white"
                          label="A Assessoria de Seguros é o elo entre o pequeno e médio corretor e as Seguradoras e Operadoras. A Assessoria presta serviço de atendimento ao corretor de forma personalizada, conforme suas necessidades, oferece suporte operacional, comercial e técnico, treinamentos para atualizações do mercado e de produtos e muitas outras facilidades."
                        >
                          <FiInfo size={30} />
                        </Tooltip>
                      </Box>
                    </Flex>
                    <Box>
                      <FormLabel fontWeight="bold">
                        Você é sócio em alguma corretora?
                      </FormLabel>
                      <RadioGroupControl name="isPartner" mb="1rem">
                        <Flex justify="space-between" w="68%">
                          <Radio value="yes">Sim</Radio>
                          <HStack
                            onClick={() => {
                              clearCnpjPartner(values);
                            }}
                          >
                            <Radio value="no">Não</Radio>
                          </HStack>
                        </Flex>
                      </RadioGroupControl>
                      {values.isPartner === 'yes' && (
                        <FormMaskedInput
                          label="Qual o CNPJ da corretora?"
                          name="cnpjPartner"
                          mask="cnpj"
                          loading={loadingCnpjPartner}
                          value={values.cnpjPartner}
                          onChange={(e) => {
                            values.cnpjPartner = e.target.value;
                            checkCnpjPartner(values, setFieldError);
                          }}
                        />
                      )}
                    </Box>
                  </InputsContainer>
                  <InputsContainer w="25rem">
                    <FormLabel htmlFor="products">
                      Selecione abaixo o(s) produto(s) que você quer se
                      cadastrar para comercializar com a SulAmérica
                    </FormLabel>
                    <Stack>
                      <CheckboxSingleControl
                        name="health"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Saúde
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="dentistry"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Odonto
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="travelInsurance"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Seguro Viagem
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="lifeInsurance"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Seguro de Vida
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="privateInsurance"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Previdência Privada
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="investments"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Investimentos
                      </CheckboxSingleControl>
                    </Stack>
                  </InputsContainer>
                </Stack>
              </Flex>
            </MainContent>

            <FooterContainer>
              <HStack
                h="100%"
                spacing={{ base: '0', md: '10', lg: '10' }}
                flexWrap="wrap"
                justify="center"
                textAlign="center"
              >
                <Link href="/">
                  <CustomButton variant="outline">VOLTAR</CustomButton>
                </Link>
                <CustomButton type="submit" isLoading={loading}>
                  AVANÇAR
                </CustomButton>
              </HStack>
            </FooterContainer>
          </Flex>
        )}
      </Formik>

      <CustomModal
        isOpen={isOpenCpfAlert}
        title="CPF já cadastrado"
        onClose={onCloseCpfAlert}
        content={
          <Text>
            Encontramos um cadastro nesse CPF. Clique aqui para ser
            redirecionado ao Canais de Atendimento.
          </Text>
        }
        buttons={
          <>
            <Stack spacing={6}>
              <Link href="https://portal.sulamericaseguros.com.br/canaisdeatendimento/">
                <CustomButton>CANAIS DE ATENDIMENTO</CustomButton>
              </Link>
              <Link href="/">
                <CustomButton variant="outline" onClick={onClose}>
                  VOLTAR PARA HOME
                </CustomButton>
              </Link>
            </Stack>
          </>
        }
      />

      <CustomModal
        isOpen={isOpen}
        title={modal.title}
        onClose={onClose}
        content={<Text>{modal.content}</Text>}
        buttons={
          <>
            <Stack>
              <Link href="/">
                <CustomButton variant="outline" onClick={onClose}>
                  VOLTAR PARA A HOME
                </CustomButton>
              </Link>
            </Stack>
          </>
        }
      />

      <CustomModal
        isOpen={isOpenError}
        title={modal.title}
        onClose={onCloseError}
        content={<Text>{modal.content}</Text>}
      />
    </>
  );
};
