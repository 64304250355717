import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';

export const NotFound = (): JSX.Element => {
  return (
    <Flex
      w="100%"
      mt="5rem"
      h="10rem"
      justify="space-between"
      align="center"
      flexDir="column"
    >
      <Box align="center" fontSize="2rem" textAlign="center">
        <Text>😢</Text>
        <Text>
          Infelizmente o conteúdo que você tentou acessar não está disponível
        </Text>
      </Box>
      <Link href="/">
        <Button>Voltar para início</Button>
      </Link>
    </Flex>
  );
};
