import {
  Flex,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { CheckboxSingleControl } from 'formik-chakra-ui';
import { useContext, useEffect, useState } from 'react';
import { CustomButton } from '../../../../components/actions/CustomButton';
import { FormFileUpload } from '../../../../components/fields/FormFileUpload';
import { FormInput } from '../../../../components/fields/FormInput';
import { FooterContainer } from '../../../../components/layouts/FooterContainer';
import { InputsContainer } from '../../../../components/layouts/InputsContainer';
import { MainContent } from '../../../../components/layouts/MainContent';
import { CustomModal } from '../../../../components/widgets/CustomModal';
import { ErrorFocus } from '../../../../components/widgets/ErrorFocus';
import { StepTimeLine } from '../../../../components/widgets/StepTimeline/StepTimeLine';
import { StepTimeLine2 } from '../../../../components/widgets/StepTimeline2/StepTimeLine';
import CnpjFormContext from '../../../../contexts/CnpjFormContext';
import { api } from '../../../../services/api';
import { FourthFormType } from '../../../../types/formTypesCnpj';
import { fourthStepValidation } from '../Validations/CnpjForm';

type Upload = {
  name: string;
  type: string;
  image: string;
};

export const FourthForm = (): JSX.Element => {
  const toast = useToast();

  const {
    stepState,
    setStepState,
    onboardingId,
    fourthStepState,
    setFourthStepState,
    firstStepState
  } = useContext(CnpjFormContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modal, setModal] = useState({
    title: '',
    content: ''
  });

  const [uploadSocialContract, setUploadSocialContract] = useState<Upload>({
    name: '',
    type: '',
    image: ''
  });
  const [uploadSusepLicense, setUploadSusepLicense] = useState<Upload>({
    name: '',
    type: '',
    image: ''
  });
  const [uploadMunicipalRegister, setUploadMunicipalRegister] =
    useState<Upload>({ name: '', type: '', image: '' });
  const [uploadAdviceLetter, setUploadAdviceLetter] = useState<Upload>({
    name: '',
    type: '',
    image: ''
  });

  const [loading, setLoading] = useState(false);
  const [loadingUploadSocialContract, setLoadingUploadSocialContract] =
    useState(false);
  const [loadingUploadSusepLicense, setLoadingUploadSusepLicense] =
    useState(false);
  const [loadingUploadMunicipalRegister, setLoadingUploadMunicipalRegister] =
    useState(false);
  const [loadingUploadAdviceLetter, setLoadingUploadAdviceLetter] =
    useState(false);

  const { isAdvice } = firstStepState;

  const isEmpty = (values: FourthFormType) => {
    return (
      !Object.values(values) ||
      (!uploadSocialContract.name.length &&
        !uploadSusepLicense.name.length &&
        !uploadMunicipalRegister.name.length &&
        !(uploadAdviceLetter.name.length && isAdvice === 'no')) ||
      (Object.values(values).every((v) => !v) &&
        Object.getOwnPropertySymbols(values).length === 0)
    );
  };

  const hasErrors = (values: FourthFormType, errors: object) => {
    return Object.keys(errors).length > 0 || isEmpty(values);
  };

  const socialContract = (fileName: string) => {
    setUploadSocialContract({
      ...uploadSocialContract,
      name: fileName
    });

    setFourthStepState({
      ...fourthStepState,
      socialContract: uploadSocialContract.name,
      // susepLicense: uploadSusepLicense.name,
      municipalRegister: uploadMunicipalRegister.name,
      isAdvice,
      adviceLetter: uploadAdviceLetter.name
    });

    setLoadingUploadSocialContract(false);
  };

  const susepLicense = (fileName: string) => {
    setUploadSusepLicense({
      ...uploadSusepLicense,
      name: fileName
    });

    setFourthStepState({
      ...fourthStepState,
      socialContract: uploadSocialContract.name,
      // susepLicense: uploadSusepLicense.name,
      municipalRegister: uploadMunicipalRegister.name,
      isAdvice,
      adviceLetter: uploadAdviceLetter.name
    });
    setLoadingUploadSusepLicense(false);
  };

  const municipalRegister = (fileName: string) => {
    setUploadMunicipalRegister({
      ...uploadMunicipalRegister,
      name: fileName
    });

    setFourthStepState({
      ...fourthStepState,
      socialContract: uploadSocialContract.name,
      // susepLicense: uploadSusepLicense.name,
      municipalRegister: uploadMunicipalRegister.name,
      isAdvice,
      adviceLetter: uploadAdviceLetter.name
    });
    setLoadingUploadMunicipalRegister(false);
  };

  const adviceLetter = (fileName: string) => {
    setUploadAdviceLetter({
      ...uploadAdviceLetter,
      name: fileName
    });

    setFourthStepState({
      ...fourthStepState,
      socialContract: uploadSocialContract.name,
      // susepLicense: uploadSusepLicense.name,
      municipalRegister: uploadMunicipalRegister.name,
      isAdvice,
      adviceLetter: uploadAdviceLetter.name
    });

    setLoadingUploadAdviceLetter(false);
  };

  const getBase64 = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (e.target?.files?.length) {
      const reader = new FileReader();

      if (
        (e.target.files[0].type !== 'application/pdf' &&
          e.target.files[0].type !== 'image/png' &&
          e.target.files[0].type !== 'image/jpeg') ||
        e.target.files[0].size / 1000 > 5000
      ) {
        toast({
          title: 'Atenção.',
          position: 'bottom',
          description:
            'Arquivo não é pdf, jpeg, png ou tamanho superior a 5MB. Favor verificar',
          status: 'warning',
          duration: 9000,
          isClosable: true
        });

        e.target.files = null;

        return;
      }

      reader.readAsDataURL(e.target.files[0]);

      reader.onload = async () => {
        if (e.target.files && reader.result) {
          console.log(e.target.name);
          try {
            e.target.name === 'socialContract'
              ? setLoadingUploadSocialContract(true)
              : e.target.name === 'susepLicense'
              ? setLoadingUploadSusepLicense(true)
              : e.target.name === 'municipalRegister'
              ? setLoadingUploadMunicipalRegister(true)
              : e.target.name === 'adviceLetter' &&
                setLoadingUploadAdviceLetter(true);

            await api.post('/signup/cnpj/salesforce-upload', {
              parentId: onboardingId,
              Name: e.target.files[0].name,
              Body: reader.result
                .toString()
                .substring(reader.result.toString().indexOf(',') + 1)
            });

            switch (e.target.name) {
              case 'socialContract':
                socialContract(e.target.files[0].name);
                break;
              case 'susepLicense':
                susepLicense(e.target.files[0].name);
                break;
              case 'municipalRegister':
                municipalRegister(e.target.files[0].name);
                break;
              case 'adviceLetter':
                adviceLetter(e.target.files[0].name);
                break;
              default:
                break;
            }

            // e.target.name === 'socialContract'
            //   ? (setUploadSocialContract({
            //       ...uploadSocialContract,
            //       name: e.target.files[0].name
            //     }),
            //     setLoadingUploadSocialContract(false))
            //   : e.target.name === 'susepLicense'
            //   ? (setUploadSusepLicense({
            //       ...uploadSusepLicense,
            //       name: e.target.files[0].name
            //     }),
            //     setLoadingUploadSusepLicense(false))
            //   : e.target.name === 'municipalRegister'
            //   ? (setUploadMunicipalRegister({
            //       ...uploadMunicipalRegister,
            //       name: e.target.files[0].name
            //     }),
            //     setLoadingUploadMunicipalRegister(false))
            //   : e.target.name === 'adviceLetter' &&
            //     (setUploadAdviceLetter({
            //       ...uploadAdviceLetter,
            //       name: e.target.files[0].name
            //     }),
            //     setLoadingUploadAdviceLetter(false));

            // e.target.name === 'socialContract'
            //   ? setUploadSocialContract({
            //       name: e.target.files[0].name.toString().split('.')[0],
            //       type: e.target.files[0].type.toString().split('/')[1],
            //       image: reader.result
            //         .toString()
            //         .substring(reader.result.toString().indexOf(',') + 1)
            //     })
            //   : e.target.name === 'susepLicense'
            //   ? setUploadSusepLicense({
            //       name: e.target.files[0].name.toString().split('.')[0],
            //       type: e.target.files[0].type.toString().split('/')[1],
            //       image: reader.result
            //         .toString()
            //         .substring(reader.result.toString().indexOf(',') + 1)
            //     })
            //   : e.target.name === 'municipalRegister'
            //   ? setUploadMunicipalRegister({
            //       name: e.target.files[0].name.toString().split('.')[0],
            //       type: e.target.files[0].type.toString().split('/')[1],
            //       image: reader.result
            //         .toString()
            //         .substring(reader.result.toString().indexOf(',') + 1)
            //     })
            //   : e.target.name === 'adviceLetter' &&
            //     setUploadAdviceLetter({
            //       name: e.target.files[0].name.toString().split('.')[0],
            //       type: e.target.files[0].type.toString().split('/')[1],
            //       image: reader.result
            //         .toString()
            //         .substring(reader.result.toString().indexOf(',') + 1)
            //     });
          } catch (error: any) {
            console.log(error);
            e.target.name === 'socialContract'
              ? setLoadingUploadSocialContract(false)
              : e.target.name === 'susepLicense'
              ? setLoadingUploadSusepLicense(false)
              : e.target.name === 'municipalRegister'
              ? setLoadingUploadMunicipalRegister(false)
              : e.target.name === 'adviceLetter' &&
                setLoadingUploadAdviceLetter(false);

            if (error?.response?.status === 429) {
              setModal({
                title: 'Ocorreu um erro',
                content:
                  'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
              });

              onOpen();
            }
          }
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const handleSubmit = async (values: FourthFormType) => {
    setLoading(true);

    try {
      // console.log({
      //   onboardingId,
      //   socialContract: uploadSocialContract,
      // //   susepLicense: uploadSusepLicense,
      //   municipalRegister: uploadMunicipalRegister,
      //   isAdvice: isAdvice === 'yes' ? true : false,
      //   adviceLetter: uploadAdviceLetter,
      //   accept: values.accept
      // });

      await api.post(
        '/signup/cnpj/third-step',
        {
          onboardingId,
          socialContract: uploadSocialContract,
          // susepLicense: uploadSusepLicense,
          municipalRegister: uploadMunicipalRegister,
          isAdvice: isAdvice === 'yes' ? true : false,
          adviceLetter: uploadAdviceLetter,
          accept: values.accept
        },
        { timeout: 7000 }
      );

      const state = {
        ...fourthStepState,
        accept: values.accept
      };

      setFourthStepState(state);

      setStepState(5);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const initialValues = {
    socialContract: fourthStepState.socialContract,
    // susepLicense: fourthStepState.susepLicense,
    municipalRegister: fourthStepState.municipalRegister,
    isAdvice,
    adviceLetter: fourthStepState.adviceLetter,
    accept: fourthStepState.accept
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={fourthStepValidation}
      >
        {({ handleSubmit }): JSX.Element => (
          <Flex
            flexDir="column"
            as="form"
            flexWrap="wrap"
            gap="12"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={handleSubmit as any}
          >
            <ErrorFocus />
            <MainContent>
              {isWideVersion && <StepTimeLine2 step={stepState} />}
              <Flex
                w="100%"
                align="center"
                flexDir="column"
                flex=".85"
                mt={isWideVersion ? '0' : '5rem'}
              >
                <Stack mb="2.5rem" spacing="0.8rem" w="25rem">
                  <Text fontSize="1.2rem">
                    Para terminar, anexe os documentos abaixo:
                  </Text>
                  <Text fontSize=".8rem">
                    Por favor, insira apenas arquivos no máximo 5 MB e nos
                    formatos .pdf, .jpeg, .png
                  </Text>
                  <Text fontSize=".8rem">* Campos obrigatórios</Text>
                </Stack>
                <Stack spacing="2rem">
                  <InputsContainer
                    maxWidth={{ base: '25rem', md: '100%', lg: '100%' }}
                  >
                    <>
                      <FormFileUpload
                        // onClick={() =>
                        //   setUploadSocialContract({
                        //     name: '',
                        //     type: '',
                        //     image: ''
                        //   })
                        // }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          getBase64(e);
                        }}
                        title={
                          uploadSocialContract.name
                            ? uploadSocialContract.name.slice(0, 18) + '(...)'
                            : 'ANEXAR'
                        }
                        isLoading={loadingUploadSocialContract}
                        text="Contrato Social e/ou última alteração *"
                        name="socialContract"
                      />
                      {/* <FormFileUpload
                        // onClick={() =>
                        //   setUploadSusepLicense({
                        //     name: '',
                        //     type: '',
                        //     image: ''
                        //   })
                        // }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          getBase64(e);
                        }}
                        title={
                          uploadSusepLicense.name
                            ? uploadSusepLicense.name.slice(0, 18) + '(...)'
                            : 'ANEXAR'
                        }
                        isLoading={loadingUploadSusepLicense}
                        text="Habilitação SUSEP *"
                        name="susepLicense"
                      /> */}
                      <FormFileUpload
                        // onClick={() =>
                        //   setUploadMunicipalRegister({
                        //     name: '',
                        //     type: '',
                        //     image: ''
                        //   })
                        // }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          getBase64(e);
                        }}
                        title={
                          uploadMunicipalRegister.name
                            ? uploadMunicipalRegister.name.slice(0, 18) +
                              '(...)'
                            : 'ANEXAR'
                        }
                        isLoading={loadingUploadMunicipalRegister}
                        text="Inscrição Municipal ou Alvará *"
                        name="municipalRegister"
                      />
                      <FormInput name="isAdvice" hidden />
                      {isAdvice === 'yes' && (
                        <FormFileUpload
                          // onClick={() =>
                          //   setUploadAdviceLetter({
                          //     name: '',
                          //     type: '',
                          //     image: ''
                          //   })
                          // }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            getBase64(e);
                          }}
                          isLoading={loadingUploadAdviceLetter}
                          title={
                            uploadAdviceLetter.name
                              ? uploadAdviceLetter.name.slice(0, 18) + '(...)'
                              : 'ANEXAR'
                          }
                          text="Carta da Assessoria *"
                          name="adviceLetter"
                        />
                      )}
                    </>
                  </InputsContainer>
                  <Stack
                    maxW={{ base: '20rem', md: '40rem', lg: '40rem' }}
                    mt="2rem"
                    spacing="1rem"
                  >
                    <HStack>
                      <CheckboxSingleControl
                        name="accept"
                        w="auto"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      />
                      <Text fontSize="1rem" ml=".8rem">
                        Declaro que todas as informações e documentos anexados
                        são de minha responsabilidade bem como declaro, sob as
                        penas da Lei, que todas as informações são verdadeiras.
                        Estou ciente que o cadastro será finalizado após a
                        análise da equipe responsável da SulAmérica.
                      </Text>
                    </HStack>
                  </Stack>
                </Stack>
              </Flex>
            </MainContent>
            <FooterContainer>
              <HStack
                h="100%"
                spacing={{ base: '0', md: '10', lg: '10' }}
                flexWrap="wrap"
                justify="center"
                textAlign="center"
              >
                <CustomButton variant="outline" onClick={() => setStepState(3)}>
                  VOLTAR
                </CustomButton>
                <CustomButton type="submit" isLoading={loading}>
                  AVANÇAR
                </CustomButton>
              </HStack>
            </FooterContainer>
          </Flex>
        )}
      </Formik>

      <CustomModal
        isOpen={isOpen}
        title={modal.title}
        onClose={onClose}
        content={<Text>{modal.content}</Text>}
      />
    </>
  );
};
