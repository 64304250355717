import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';
import {
  FirstFormType,
  FourthFormType,
  SecondFormType,
  ThirdFormType
} from '../types/formTypesCnpj';

type CnpjFormContextProps = {
  stepState: number;
  setStepState: Dispatch<SetStateAction<number>>;

  onboardingId: string;
  setOnboardingId: Dispatch<SetStateAction<string>>;

  firstStepState: FirstFormType;
  setFirstStepState: Dispatch<SetStateAction<FirstFormType>>;

  secondStepState: SecondFormType;
  setSecondStepState: Dispatch<SetStateAction<SecondFormType>>;

  thirdStepState: ThirdFormType;
  setThirdStepState: Dispatch<SetStateAction<ThirdFormType>>;

  fourthStepState: FourthFormType;
  setFourthStepState: Dispatch<SetStateAction<FourthFormType>>;
};

const initialValues = {
  stepState: 1,
  onboardingId: '',
  firstStepState: {
    cnpj: '',
    company: '',
    statusCnpj: '',
    impededCnpj: false,
    firstCnae: '',
    secondCnaes: [''],
    postalCode: '',
    address: '',
    number: '',
    district: '',
    city: '',
    state: '',
    complement: '',
    isMailAddress: true,
    mailPostalCode: '',
    mailAddress: '',
    mailNumber: '',
    mailDistrict: '',
    mailCity: '',
    mailState: '',
    mailComplement: '',
    susep: '',
    phone1: '',
    frame1: '',
    phone2: '',
    frame2: '',
    email: '',
    isAdvice: '',
    advice: '',
    responsible: '',
    responsibleCpf: '',
    responsibleSusep: '',
    responsibleImpeded: false,
    responsiblePep: false,
    health: false,
    dentistry: false,
    lifeInsurance: false,
    travelInsurance: false,
    privateInsurance: false,
    investments: false,
    partnerName: '',
    partnerCpfCnpj: '',
    partnerPercentage: '',
    partnerSusep: '',
    partners: [
      {
        name: '',
        cpfCnpj: '',
        impeded: false,
        pep: false,
        percentage: '0',
        susep: '',
        status: ''
      }
    ],
    isSimplesNacional: '',
    aliquot: '',
    acceptIsSimpleNacional: false
  },

  secondStepState: {
    experience: '',
    workPlace: '',
    employees: '',
    healthValue: '',
    dentistryValue: '',
    lifeInsuranceValue: '',
    travelInsuranceValue: '',
    privateInsuranceValue: '',
    investmentsValue: '',
    // internalContact: '',
    // branchContact: '',
    // employerContact: '',
    firstInsurer: '',
    secondInsurer: '',
    thirdInsurer: '',
    otherInsurer: ''
  },

  thirdStepState: {
    bank: '',
    agency: '',
    agencyDigit: '',
    account: '',
    digit: '',
    type: '',
    acceptTerm: false,
    acceptDeclaration: false,
    acceptAuthorization: false
  },

  fourthStepState: {
    socialContract: '',
    // susepLicense: '',
    municipalRegister: '',
    isAdvice: '',
    adviceLetter: '',
    accept: false
  },

  setFirstStepState: () => {
    ('');
  },

  setSecondStepState: () => {
    ('');
  },

  setThirdStepState: () => {
    ('');
  },

  setFourthStepState: () => {
    ('');
  },

  setStepState: () => {
    ('');
  },

  setOnboardingId: () => {
    ('');
  }
};

const CnpjFormContext = createContext<CnpjFormContextProps>(initialValues);

const CnpjFormContextProvider: FC = ({ children }) => {
  const [stepState, setStepState] = useState(initialValues.stepState);

  const [onboardingId, setOnboardingId] = useState(initialValues.onboardingId);

  const [firstStepState, setFirstStepState] = useState(
    initialValues.firstStepState
  );

  const [secondStepState, setSecondStepState] = useState(
    initialValues.secondStepState
  );

  const [thirdStepState, setThirdStepState] = useState(
    initialValues.thirdStepState
  );

  const [fourthStepState, setFourthStepState] = useState(
    initialValues.fourthStepState
  );

  return (
    <CnpjFormContext.Provider
      value={{
        stepState,
        onboardingId,
        setOnboardingId,
        setStepState,
        firstStepState,
        setFirstStepState,
        secondStepState,
        setSecondStepState,
        thirdStepState,
        setThirdStepState,
        fourthStepState,
        setFourthStepState
      }}
    >
      {children}
    </CnpjFormContext.Provider>
  );
};
export { CnpjFormContextProvider };
export default CnpjFormContext;
