import { useLocation } from 'react-router-dom';
import { NotFound } from '../NotFound';
import { CnpjForm } from './Forms/CnpjForm';
import { CpfForm } from './Forms/CpfForm';

export const SignUp = (): JSX.Element => {
  const location = useLocation();

  const { pathname } = location;

  return (
    <>
      {pathname === '/cadastro/juridico' ? (
        <CnpjForm />
      ) : pathname === '/cadastro/fisico' ? (
        <CpfForm />
      ) : (
        <NotFound />
      )}
    </>
  );
};
