import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Home } from '../pages/Home';
import { NotFound } from '../pages/NotFound';
import { SignUp } from '../pages/SignUp/SignUp';

export const AppRoutes = (): JSX.Element => {
  return (
    <Router>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/cadastro/fisico" element={<SignUp />} />

        <Route path="/cadastro/juridico" element={<SignUp />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <ToastContainer theme="colored" position="top-right" autoClose={7000} />
    </Router>
  );
};
