export const insurers = [
  'ALLIANZ',
  'AMIL',
  'BANCO DO BRASIL',
  'BRADESCO',
  'CAIXA SEGUROS',
  'CARDIF',
  'CHUBB',
  'GENERALI',
  'HAPVIDA',
  'ICATU',
  'INPAO',
  'INTERODONTO',
  'ITAU',
  'LIBERTY',
  'MAG - MONGERAL AEGON',
  'MAPFRE',
  'METLIFE',
  'NOTREDAME INTERMEDICA',
  'ODONTO PREV',
  'OMINT',
  'PORTO SEGURO',
  'PREVENT SENIOR',
  'PRUDENTIAL',
  'SOMPO',
  'TOKIO MARINE',
  'UNIMED',
  'ZURICH - SANTANDER'
];

export const banks = [
  {
    id: '1',
    name: 'BANCO DO BRASIL'
  },
  {
    id: '3',
    name: 'BASA - BANCO DA AMAZÔNIA'
  },
  {
    id: '4',
    name: 'BANCO NORDESTE DO BRASIL S/A'
  },
  {
    id: '10',
    name: 'CREDICOAMO'
  },
  {
    id: '15',
    name: 'UBS Brasil Corretora de Câmbio'
  },
  {
    id: '16',
    name: 'SICOOB CREDITRAN'
  },
  {
    id: '17',
    name: 'Banco BNY Mellon S.A.'
  },
  {
    id: '19',
    name: 'BANCO AZTECA'
  },
  {
    id: '21',
    name: 'BANESTES'
  },
  {
    id: '22',
    name: 'CRED REAL MTG'
  },
  {
    id: '24',
    name: 'BANCO BANDEPE'
  },
  {
    id: '25',
    name: 'BANCO ALFA'
  },
  {
    id: '27',
    name: 'BANCO ESTADO DE STA CATARINA'
  },
  {
    id: '28',
    name: 'BANCO BANEB'
  },
  {
    id: '29',
    name: 'BANCO BANERJ'
  },
  {
    id: '31',
    name: 'BANCO ESTADO DE GOIAS S/A'
  },
  {
    id: '33',
    name: 'BANCO SANTANDER BRASIL SA'
  },
  {
    id: '34',
    name: 'BANCO ESTADO DO AMAZONAS'
  },
  {
    id: '35',
    name: 'BANCO ESTADO DO CEARA'
  },
  {
    id: '36',
    name: 'BANCO ESTADO DO MARANHÃO'
  },
  {
    id: '37',
    name: 'BANCO ESTADO DO PARA'
  },
  {
    id: '38',
    name: 'BANCO ESTADO DO PARANA'
  },
  {
    id: '39',
    name: 'BCO EST. DO PIAUI S/A'
  },
  {
    id: '41',
    name: 'BANCO ESTADO RIO GRANDE DO SUL'
  },
  {
    id: '47',
    name: 'BANCO BANESE'
  },
  {
    id: '64',
    name: 'Goldman Sachs do Brasil Bco Mu'
  },
  {
    id: '66',
    name: 'BANCO MORGAN STANLEY S/A'
  },
  {
    id: '70',
    name: 'BRB - BCO DE BRASILIA S.A.'
  },
  {
    id: '77',
    name: 'Banco Intermedium S/A'
  },
  {
    id: '82',
    name: 'Banco Topázio S.A.'
  },
  {
    id: '83',
    name: 'Banco da China Brasil S/A'
  },
  {
    id: '84',
    name: 'UNIPRIME NORTE DO PARANÁ'
  },
  {
    id: '85',
    name: 'COOPERATIVA CENTRAL DE CRÉDITO'
  },
  {
    id: '87',
    name: 'COOPERATIVA UNICRED CENTRAL ST'
  },
  {
    id: '89',
    name: 'BANCO CREDISAN'
  },
  {
    id: '90',
    name: 'Cooperativa das Unicreds'
  },
  {
    id: '91',
    name: 'Unicred Central do RJ'
  },
  {
    id: '94',
    name: 'Petra'
  },
  {
    id: '97',
    name: 'Banco Jicred'
  },
  {
    id: '99',
    name: 'Uniprime Central'
  },
  {
    id: '102',
    name: 'Xp Investimentos S.A.'
  },
  {
    id: '104',
    name: 'CAIXA ECON. FEDERAL'
  },
  {
    id: '112',
    name: 'UNICRED MATO GROSSO'
  },
  {
    id: '121',
    name: 'Banco Agibank S.A.'
  },
  {
    id: '124',
    name: 'Banco Woori Bank do Brasil S.A'
  },
  {
    id: '125',
    name: 'Banco Brasil Plural'
  },
  {
    id: '133',
    name: 'Cresol Cooperativa'
  },
  {
    id: '136',
    name: 'Conf.Nac.Coop Centrais Unicred'
  },
  {
    id: '151',
    name: 'NOSSA CAIXA NOSSO BANCO S/A'
  },
  {
    id: '184',
    name: 'BBA CREDITANSTALT S/A'
  },
  {
    id: '190',
    name: 'Servicoop'
  },
  {
    id: '197',
    name: 'Stone Pagamentos S.A.'
  },
  {
    id: '205',
    name: 'BANCO SUL AMERICA'
  },
  {
    id: '208',
    name: 'BANCO PACTUAL'
  },
  {
    id: '212',
    name: 'BANCO ORIGINAL'
  },
  {
    id: '213',
    name: 'BANCO ARBI SA'
  },
  {
    id: '215',
    name: 'BCO AMERICA DO SUL'
  },
  {
    id: '217',
    name: 'Banco John Deere S/A'
  },
  {
    id: '218',
    name: 'Bonsucesso Factoring LTDA'
  },
  {
    id: '222',
    name: 'BANCO CREDIT AGRICOLE BRASIL'
  },
  {
    id: '224',
    name: 'BANCO FIBRA S/A'
  },
  {
    id: '225',
    name: 'BANCO BRASCAN'
  },
  {
    id: '231',
    name: 'BANCO BOAVISTA S.A.'
  },
  {
    id: '237',
    name: 'BANCO BRADESCO S.A.'
  },
  {
    id: '244',
    name: 'BANCO CIDADE S/A'
  },
  {
    id: '246',
    name: 'BANCO ABC BRASIL S.A'
  },
  {
    id: '254',
    name: 'Parana Banco S/A'
  },
  {
    id: '255',
    name: 'BANCO BRASCAN'
  },
  {
    id: '260',
    name: 'NU PAGAMENTOS S.A'
  },
  {
    id: '265',
    name: 'Banco Fator S/A'
  },
  {
    id: '274',
    name: 'MONEY PLUS SCMEPP LTDA'
  },
  {
    id: '290',
    name: 'Pagseguro Internet S.A'
  },
  {
    id: '291',
    name: 'BANCO CREDITO NACIONAL S/A'
  },
  {
    id: '300',
    name: 'Banco de La Nacion Argentina'
  },
  {
    id: '301',
    name: 'BPP Instituições de pagamentos'
  },
  {
    id: '318',
    name: 'Banco BMG'
  },
  {
    id: '320',
    name: 'BCO INDUSTRIAL E COMERCIAL'
  },
  {
    id: '323',
    name: 'Mercado Pago.Com Repres. LTDA'
  },
  {
    id: '325',
    name: 'Órama Dist de Tít e Val Mob SA'
  },
  {
    id: '329',
    name: 'QI Sociedade de Crédito'
  },
  {
    id: '332',
    name: 'Acesso Soluções de Pagamento'
  },
  {
    id: '336',
    name: 'C6 Bank'
  },
  {
    id: '341',
    name: 'BANCO ITAU'
  },
  {
    id: '342',
    name: 'CATÃO REDE'
  },
  {
    id: '348',
    name: 'BANCO XP S.A.'
  },
  {
    id: '353',
    name: 'SANTANDER DO BRASIL'
  },
  {
    id: '356',
    name: 'BANCO REAL'
  },
  {
    id: '366',
    name: 'Banco Societe Generale Brasil'
  },
  {
    id: '376',
    name: 'BANCO J.P. MORGAN S.A.'
  },
  {
    id: '378',
    name: 'BBC Leasing S.A.'
  },
  {
    id: '380',
    name: 'PICPAY SERVIÇOS'
  },
  {
    id: '383',
    name: 'JUNO'
  },
  {
    id: '389',
    name: 'MERCANTIL DO BRASIL'
  },
  {
    id: '394',
    name: 'BCO MERCANTIL DE CRÉDITO'
  },
  {
    id: '399',
    name: 'HSBC BAMERINDUS'
  },
  {
    id: '403',
    name: 'CORA SOC. DE CRÉDITO DIRETO'
  },
  {
    id: '409',
    name: 'UNIBANCO'
  },
  {
    id: '413',
    name: 'BANCO BV'
  },
  {
    id: '422',
    name: 'BANCO SAFRA S.A.'
  },
  {
    id: '450',
    name: 'FITBANK'
  },
  {
    id: '453',
    name: 'BANCO RURAL S.A.'
  },
  {
    id: '456',
    name: 'BANCO DE TOKYO-MITSUBISHI UFJ'
  },
  {
    id: '462',
    name: 'BANCO STARK'
  },
  {
    id: '473',
    name: 'Banco Caixa Geral - Brasil S.A'
  },
  {
    id: '479',
    name: 'BANCO DE BOSTON'
  },
  {
    id: '487',
    name: 'DEUTSCHE BANK'
  },
  {
    id: '492',
    name: 'BANCO BANK M.V.'
  },
  {
    id: '505',
    name: 'BANCO CSFB'
  },
  {
    id: '536',
    name: 'BANCO NEON'
  },
  {
    id: '604',
    name: 'Banco Industrial do Brasil'
  },
  {
    id: '610',
    name: 'VR S/A'
  },
  {
    id: '611',
    name: 'Banco Paulista S/A'
  },
  {
    id: '623',
    name: 'BANCO PANAMERICANO'
  },
  {
    id: '633',
    name: 'BANCO RENDIMENTO S/A'
  },
  {
    id: '634',
    name: 'BANCO TRIÂNGULO'
  },
  {
    id: '637',
    name: 'BANCO SOFISA S.A.'
  },
  {
    id: '641',
    name: 'BBVA'
  },
  {
    id: '643',
    name: 'BANCO PINE S/A'
  },
  {
    id: '653',
    name: 'INDUSVAL SA'
  },
  {
    id: '655',
    name: 'BANCO VOTORANTIM'
  },
  {
    id: '658',
    name: 'BANCO PORTO REAL S/A'
  },
  {
    id: '707',
    name: 'DAYCOVAL SA'
  },
  {
    id: '712',
    name: 'Banco Ourinvest S.A.'
  },
  {
    id: '735',
    name: 'Banco Neon S/A'
  },
  {
    id: '741',
    name: 'Banco Ribeirão Preto S/A'
  },
  {
    id: '743',
    name: 'SEMEAR S.A.'
  },
  {
    id: '745',
    name: 'BANCO CITIBANK'
  },
  {
    id: '746',
    name: 'Banco Modal S.A.'
  },
  {
    id: '747',
    name: 'Banco Rabobank'
  },
  {
    id: '748',
    name: 'SICREDI'
  },
  {
    id: '752',
    name: 'BANCO BNP PARIBÁS BRASIL S/A'
  },
  {
    id: '755',
    name: 'BCO MERRILL LYNCH DE INVEST SA'
  },
  {
    id: '756',
    name: 'BANCO COOPERATIVO DO BRASIL SA'
  },
  {
    id: '069',
    name: 'Crefisa'
  }
];
