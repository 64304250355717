import * as Yup from 'yup';

export const firstStepValidation = Yup.object({
  cnpj: Yup.string()
    .required('CNPJ é obrigatório')
    .min(18, 'CNPJ inválido')
    .max(18, 'CNPJ inválido'),
  company: Yup.string().required('Favor informar'),
  postalCode: Yup.string().required('Favor informar'),
  address: Yup.string().required('Favor informar'),
  number: Yup.string().required('Favor informar'),
  district: Yup.string().required('Favor informar'),
  city: Yup.string().required('Favor informar'),
  state: Yup.string().required('Favor selecionar'),
  complement: Yup.string(),
  isMailAddress: Yup.boolean().required(),
  mailPostalCode: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor informar')
  }),
  mailAddress: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor informar')
  }),
  mailNumber: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor informar')
  }),
  mailDistrict: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor informar')
  }),
  mailCity: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor informar')
  }),
  mailState: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor selecionar')
  }),
  mailComplement: Yup.string(),
  susep: Yup.string().required('SUSEP inválida'),
  phone1: Yup.string()
    .required('Celular inválido')
    .min(13, 'Celular inválido')
    .max(14, 'Celular inválido'),
  frame1: Yup.number().typeError('Ramal inválido'),
  phone2: Yup.string()
    .min(13, 'Telefone inválido')
    .max(14, 'Telefone inválido'),
  frame2: Yup.number().typeError('Ramal inválido'),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  isAdvice: Yup.string().required('Favor informar'),
  // advice: Yup.string().when('isAdvice', {
  //   is: 'yes',
  //   then: Yup.string().required('Favor selecione uma assessoria')
  // }),
  responsible: Yup.string().required('Favor informar'),
  responsibleCpf: Yup.string()
    .required('Favor informar')
    .min(14, 'CPF inválido'),
  responsibleSusep: Yup.string().required('SUSEP inválido'),
  health: Yup.boolean(),
  dentistry: Yup.boolean(),
  lifeInsurance: Yup.boolean(),
  travelInsurance: Yup.boolean(),
  privateInsurance: Yup.boolean(),
  investments: Yup.boolean(),
  partners: Yup.array().of(
    Yup.object().shape({
      partnerName: Yup.string(),
      partnerCpfCnpj: Yup.string().min(14, 'CNPJ inválido'),
      partnerPercentage: Yup.number().max(100),
      partnerSusep: Yup.string()
    })
  ),
  partnerCpfCnpj: Yup.string().min(14, 'CNPJ inválido'),
  isSimplesNacional: Yup.string().required('Favor informar'),
  acceptIsSimpleNacional: Yup.boolean().when('isSimplesNacional', {
    is: 'yes',
    then: Yup.boolean().required('Favor confirmar')
  }),
  aliquot: Yup.number().when('isSimplesNacional', {
    is: 'yes',
    then: Yup.number()
      .transform((_, value) => {
        if (value.includes('.')) {
          return +value.replace(/,/, '.');
        }
        return +value.replace(/,/, '.');
      })
      .positive()
      .required('Valor deve ser entre 2% e 5%')
      .min(2, 'Valor deve ser entre 2% e 5%')
      .max(5, 'Valor deve ser entre 2% e 5%')
  })
})
  .test('investments', 'Favor selecione pelo menos um', (obj) => {
    if (
      obj?.health ||
      obj?.dentistry ||
      obj?.lifeInsurance ||
      obj?.travelInsurance ||
      obj?.privateInsurance ||
      obj?.investments
    ) {
      return true;
    }

    return new Yup.ValidationError(
      'Favor selecione pelo menos um',
      null,
      'investments'
    );
  })
  .test('acceptIsSimpleNacional', 'Favor selecione', (obj) => {
    if (obj?.acceptIsSimpleNacional || obj?.isSimplesNacional !== 'yes') {
      return true;
    }

    return new Yup.ValidationError(
      'Favor selecione',
      null,
      'acceptIsSimpleNacional'
    );
  });

export const secondStepValidation = Yup.object({
  experience: Yup.string().required('Experiência é obrigatória'),
  workPlace: Yup.string().required('Favor selecionar'),
  // workPlaceType: Yup.string().when('workPlace', {
  //   is: 'yes',
  //   then: Yup.string().required('Favor selecione um tipo de local')
  // }),

  employees: Yup.string().required('Colaboradores é obrigatório'),

  healthValue: Yup.string().required('Favor selecionar'),
  dentistryValue: Yup.string().required('Favor selecionar'),
  lifeInsuranceValue: Yup.string().required('Favor selecionar'),
  travelInsuranceValue: Yup.string().required('Favor selecionar'),
  privateInsuranceValue: Yup.string().required('Favor selecionar'),
  investmentsValue: Yup.string().required('Favor selecionar'),

  // internalContact: Yup.string().required(),
  // branchContact: Yup.string().when('internalContact', {
  //   is: 'yes',
  //   then: Yup.string().required('Favor selecione uma filial')
  // }),
  // employerContact: Yup.string().when('internalContact', {
  //   is: 'yes',
  //   then: Yup.string().required('Favor selecione um(a) colaborador(a)')
  // }),

  otherInsurer: Yup.string(),

  firstInsurer: Yup.string().when('otherInsurer', {
    is: (otherInsurer: string) => {
      return otherInsurer === undefined;
    },
    then: Yup.string().required('Favor selecionar')
  }),

  secondInsurer: Yup.string().when(['otherInsurer', 'firstInsurer'], {
    is: (otherInsurer: string, firstInsurer: string) => {
      return (
        otherInsurer === undefined &&
        firstInsurer !== 'AINDA NÃO TRABALHO COM SEGURADORAS'
      );
    },
    then: Yup.string().required('Favor selecionar')
  }),
  thirdInsurer: Yup.string().when(['otherInsurer', 'firstInsurer'], {
    is: (otherInsurer: string, firstInsurer: string) => {
      return (
        otherInsurer === undefined &&
        firstInsurer !== 'AINDA NÃO TRABALHO COM SEGURADORAS'
      );
    },
    then: Yup.string().required('Favor selecionar')
  })
});

export const thirdStepValidation = Yup.object({
  bank: Yup.string().required('Favor selecionar um banco'),
  agency: Yup.string().required('Favor informar uma agência'),
  agencyDigit: Yup.string(),
  account: Yup.string().required('Favor informar o número da conta'),
  digit: Yup.string().required('Favor informar'),
  type: Yup.string().required('Favor selecionar o tipo'),
  acceptTerm: Yup.boolean().required(),
  acceptDeclaration: Yup.boolean().required(),
  acceptAuthorization: Yup.boolean().required()
})
  .test('acceptTerm', 'Selecionar', (obj) => {
    if (obj?.acceptTerm) {
      return true;
    }

    return new Yup.ValidationError('Selecione', null, 'acceptTerm');
  })
  .test('acceptDeclaration', 'Selecione', (obj) => {
    if (obj?.acceptDeclaration) {
      return true;
    }

    return new Yup.ValidationError('Selecione', null, 'acceptDeclaration');
  })
  .test('acceptAuthorization', 'Selecione', (obj) => {
    if (obj?.acceptAuthorization) {
      return true;
    }

    return new Yup.ValidationError('Selecione', null, 'acceptAuthorization');
  });

export const fourthStepValidation = Yup.object({
  socialContract: Yup.mixed().required('Favor anexar'),
  // susepLicense: Yup.mixed().required('Favor anexar'),
  municipalRegister: Yup.mixed().required('Favor anexar'),
  isAdvice: Yup.string(),
  adviceLetter: Yup.mixed().when('isAdvice', {
    is: 'yes',
    then: Yup.mixed().required('Favor anexar')
  }),
  accept: Yup.boolean().required()
}).test('accept', 'Selecione', (obj) => {
  if (obj?.accept) {
    return true;
  }

  return new Yup.ValidationError('Selecione', null, 'accept');
});

export const fifthStepValidation = Yup.object({
  rating: Yup.number().required('Favor selecione um valor para a avaliação')
});
