import { Flex, Input, InputProps, Spinner } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ForwardedRef, InputHTMLAttributes } from 'react';
import { BaseProps, FormControl } from './FormControl';

type FormInput = {
  mask?: string;
  type?: string;
  min?: number;
  max?: number;
  loading?: boolean;
};

type InputControlProps = FormInput &
  BaseProps &
  InputHTMLAttributes<HTMLInputElement> & { inputProps?: InputProps };

// eslint-disable-next-line react/display-name
export const FormInput: React.FC<InputControlProps> = React.forwardRef(
  (props: InputControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      name,
      label,
      mask,
      type,
      min,
      max,
      placeholder,
      inputProps,
      loading,
      ...rest
    } = props;

    const [field] = useField(name);

    return (
      <FormControl name={name} label={label} {...rest} mb="1.2rem">
        <Flex justify="center" align="center">
          <Input
            id={name}
            type={type}
            borderColor={loading || props.isDisabled ? 'gray.100' : 'gray.800'}
            variant="flushed"
            min={min}
            mask={mask}
            maxLength={max ? max : 524288}
            placeholder={placeholder}
            isDisabled={loading}
            mb="1.5rem"
            {...field}
            {...rest}
            {...inputProps}
          />

          {loading && (
            <Spinner h=".8rem" w=".8rem" position="absolute" mb="1.5rem" />
          )}
        </Flex>
      </FormControl>
    );
  }
);
