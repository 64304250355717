import * as Yup from 'yup';

export const firstStepValidation = Yup.object({
  cpf: Yup.string()
    .required('CPF é obrigatório')
    .min(14, 'CPF inválido')
    .max(14, 'CPF inválido'),
  name: Yup.string().required('Nome é obrigatório'),
  birthday: Yup.string().required('Data de nascimento é obrigatória'),
  postalCode: Yup.string().required('CEP é obrigatório'),
  address: Yup.string().required('Endereço é obrigatório'),
  number: Yup.string().required('Número é obrigatório'),
  district: Yup.string().required('Bairro é obrigatório'),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Favor selecionar'),
  complement: Yup.string(),
  isMailAddress: Yup.boolean().required(),
  mailPostalCode: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor preencher')
  }),
  mailAddress: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor preencher')
  }),
  mailNumber: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor preencher')
  }),
  mailDistrict: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor preencher')
  }),
  mailCity: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor preencher')
  }),
  mailState: Yup.string().when('isMailAddress', {
    is: false,
    then: Yup.string().required('Favor selecionar')
  }),
  mailComplement: Yup.string(),
  susep: Yup.string().required('SUSEP é obrigatório'),
  phone1: Yup.string()
    .required('Celular é obrigatório')
    .min(13, 'Celular inválido')
    .max(14, 'Celular inválido'),
  frame1: Yup.number().typeError('Ramal inválido'),
  phone2: Yup.string()
    .min(13, 'Telefone inválido')
    .max(14, 'Telefone inválido'),
  frame2: Yup.number().typeError('Ramal inválido'),
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  isAdvice: Yup.string().required('Favor informar'),
  health: Yup.boolean(),
  dentistry: Yup.boolean(),
  lifeInsurance: Yup.boolean(),
  travelInsurance: Yup.boolean(),
  privateInsurance: Yup.boolean(),
  investments: Yup.boolean(),
  isPartner: Yup.string().required('Selecione'),
  cnpjPartner: Yup.string().when('isPartner', {
    is: 'yes',
    then: Yup.string().required('Informar')
  })
}).test('investments', 'Favor selecione pelo menos um', (obj) => {
  if (
    obj?.health ||
    obj?.dentistry ||
    obj?.lifeInsurance ||
    obj?.travelInsurance ||
    obj?.privateInsurance ||
    obj?.investments
  ) {
    return true;
  }

  return new Yup.ValidationError(
    'Favor selecione pelo menos um',
    null,
    'investments'
  );
});

export const secondStepValidation = Yup.object({
  experience: Yup.string().required('Experiência é obrigatória'),
  workPlace: Yup.string().required('Favor selecionar'),
  estimatedIncome: Yup.string().required('Favor selecionar'),
  exactIncome: Yup.string().when('estimatedIncome', {
    is: (estimatedIncome: string) => {
      return estimatedIncome === 'R$ 6.000,01 ou superior';
    },
    then: Yup.string()
      .required('Digite um valor acima de R$ 6.000,01')
      .min(8, 'Digite um valor acima de R$ 6.000,01')
  }),
  healthValue: Yup.string().required('Favor selecionar'),
  dentistryValue: Yup.string().required('Favor selecionar'),
  lifeInsuranceValue: Yup.string().required('Favor selecionar'),
  travelInsuranceValue: Yup.string().required('Favor selecionar'),
  privateInsuranceValue: Yup.string().required('Favor selecionar'),
  investmentsValue: Yup.string().required('Favor selecionar'),
  otherInsurer: Yup.string(),
  firstInsurer: Yup.string().when('otherInsurer', {
    is: (otherInsurer: string) => {
      return otherInsurer === undefined;
    },
    then: Yup.string().required('Favor selecionar')
  }),
  secondInsurer: Yup.string().when(['otherInsurer', 'firstInsurer'], {
    is: (otherInsurer: string, firstInsurer: string) => {
      return (
        otherInsurer === undefined &&
        firstInsurer !== 'AINDA NÃO TRABALHO COM SEGURADORAS'
      );
    },
    then: Yup.string().required('Favor selecionar')
  }),
  thirdInsurer: Yup.string().when(['otherInsurer', 'firstInsurer'], {
    is: (otherInsurer: string, firstInsurer: string) => {
      return (
        otherInsurer === undefined &&
        firstInsurer !== 'AINDA NÃO TRABALHO COM SEGURADORAS'
      );
    },
    then: Yup.string().required('Favor selecionar')
  })
});

export const thirdStepValidation = Yup.object({
  bank: Yup.string().required('Favor selecionar'),
  agency: Yup.string().required('Favor informar'),
  agencyDigit: Yup.string(),
  account: Yup.string().required('Favor informar'),
  digit: Yup.string().required('Favor informar'),
  type: Yup.string().required('Favor selecionar'),
  acceptTerm: Yup.boolean().required(),
  acceptDeclaration: Yup.boolean().required(),
  acceptAuthorization: Yup.boolean().required()
})
  .test('acceptTerm', 'Selecionar', (obj) => {
    if (obj?.acceptTerm) {
      return true;
    }

    return new Yup.ValidationError('Selecione', null, 'acceptTerm');
  })
  .test('acceptDeclaration', 'Selecione', (obj) => {
    if (obj?.acceptDeclaration) {
      return true;
    }

    return new Yup.ValidationError('Selecione', null, 'acceptDeclaration');
  })
  .test('acceptAuthorization', 'Selecione', (obj) => {
    if (obj?.acceptAuthorization) {
      return true;
    }

    return new Yup.ValidationError('Selecione', null, 'acceptAuthorization');
  });

export const fourthStepValidation = Yup.object({
  residence: Yup.mixed().required('Favor anexar'),
  cpf: Yup.mixed().required('Favor anexar'),
  pisPasepInss: Yup.mixed().required('Favor anexar'),
  municipalRegister: Yup.mixed().required('Favor anexar'),
  eSocial: Yup.mixed().required('Favor anexar'),
  isAdvice: Yup.string(),
  adviceLetter: Yup.mixed().when('isAdvice', {
    is: 'yes',
    then: Yup.mixed().required('Favor anexar')
  }),
  accept: Yup.boolean().required()
}).test('accept', 'Selecione', (obj) => {
  if (obj?.accept) {
    return true;
  }

  return new Yup.ValidationError('Selecione', null, 'accept');
});

export const fifthStepValidation = Yup.object({
  rating: Yup.number().required('Favor selecione um valor para a avaliação')
});
