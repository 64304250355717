import { Flex, FlexProps } from '@chakra-ui/react';

type Props = FlexProps & {
  children: React.ReactNode;
};

export const PageContent = ({ children, ...props }: Props) => {
  return (
    <Flex {...props} flexDir="column" flex="1">
      {children}
    </Flex>
  );
};
