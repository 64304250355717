import { Button, ButtonProps } from '@chakra-ui/react';

type Props = ButtonProps & {
  children: string;
};

export const CustomButton = ({ children, ...props }: Props): JSX.Element => {
  return (
    <Button {...props} w="17.5rem" h="3rem" borderRadius=".25rem">
      {children}
    </Button>
  );
};
