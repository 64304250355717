import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type Modal = {
  title?: string;
  content?: ReactNode;
  buttons?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const CustomModal = ({
  title,
  content,
  buttons,
  isOpen,
  onClose
}: Modal): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        p="2.25rem"
        textAlign="center"
        w="28rem"
        borderRadius="1.2rem"
        bg="#fff"
        boxShadow="0px 12px 30px rgba(34, 51, 75, 0.85)"
      >
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalCloseButton size="lg" />
        {content && (
          <ModalBody
            justify="center"
            align="center"
            mb="1rem"
            fontSize="1.1rem"
          >
            {content}
          </ModalBody>
        )}
        <ModalFooter justify="center" align="center" flexDir="column">
          <Stack>{buttons}</Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
