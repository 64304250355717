import {
  Button,
  HStack,
  Input,
  InputProps,
  Spinner,
  Text
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ForwardedRef, useRef } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { MdOutlineUpload } from 'react-icons/md';
import { BaseProps, FormControl } from './FormControl';

type FormInput = {
  text?: string;
  title: string;
  isLoading?: boolean;
};

type InputControlProps = FormInput & BaseProps & { inputProps?: InputProps };

// eslint-disable-next-line react/display-name
export const FormFileUpload: React.FC<InputControlProps> = React.forwardRef(
  (props: InputControlProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { name, label, text, title, isLoading, inputProps, ...rest } = props;

    const [field] = useField(name);

    const inputRef = useRef<HTMLInputElement | null>(null);

    return (
      <FormControl name={name} label={label} {...rest} mb="1.2rem">
        <HStack mb="1rem" justify="space-between" flexWrap="wrap">
          <Text>{text}</Text>
          <Button
            w="15rem"
            leftIcon={
              isLoading ? (
                <Spinner />
              ) : title !== 'ANEXAR' ? (
                <BsCheckCircleFill size={25} />
              ) : (
                <MdOutlineUpload size={25} />
              )
            }
            onClick={() => inputRef.current?.click()}
          >
            {!isLoading && title}
          </Button>
          <Input
            accept="image/png, image/jpeg, application/pdf"
            hidden
            ref={inputRef}
            id={name}
            {...field}
            {...inputProps}
            type="file"
            borderColor="orange.500"
          />
        </HStack>
      </FormControl>
    );
  }
);
