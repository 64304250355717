/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Input,
  Link,
  Radio,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react';
import { Formik } from 'formik';
import {
  CheckboxSingleControl,
  RadioGroupControl,
  SwitchControl
} from 'formik-chakra-ui';
import { useContext, useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { MdOutlineAddCircleOutline } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { CustomButton } from '../../../../components/actions/CustomButton';
import { FormInput } from '../../../../components/fields/FormInput';
import { FormMaskedInput } from '../../../../components/fields/FormMaskedInput';
import { FormSelect } from '../../../../components/fields/FormSelect';
import { FooterContainer } from '../../../../components/layouts/FooterContainer';
import { InputsContainer } from '../../../../components/layouts/InputsContainer';
import { MainContent } from '../../../../components/layouts/MainContent';
import { CustomModal } from '../../../../components/widgets/CustomModal';
import { ErrorFocus } from '../../../../components/widgets/ErrorFocus';
import { StepTimeLine2 } from '../../../../components/widgets/StepTimeline2/StepTimeLine';
import CnpjFormContext from '../../../../contexts/CnpjFormContext';
import { api } from '../../../../services/api';
import { FirstFormType } from '../../../../types/formTypesCnpj';
import { isCnpjValid } from '../../../../utils/checkers/checkCNPJ';
import { states } from '../../../../utils/statics';
import { firstStepValidation } from '../Validations/CnpjForm';

type Partner = {
  name: string;
  cpfCnpj: string;
  impeded: boolean;
  pep: boolean;
  percentage: string;
  susep: string;
  status: string;
};

export const FirstForm = (): JSX.Element => {
  const {
    stepState,
    setStepState,
    onboardingId,
    setOnboardingId,
    firstStepState,
    setFirstStepState
  } = useContext(CnpjFormContext);

  const {
    isMailAddress: isMailAddressState,
    statusCnpj,
    impededCnpj,
    firstCnae,
    secondCnaes,
    statusSusep,
    responsibleImpeded,
    responsiblePep,
    partners
  } = firstStepState;

  const [loading, setLoading] = useState(false);
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [loadingMailPostalCode, setLoadingMailPostalCode] = useState(false);
  const [loadingCpf, setLoadingCpf] = useState(false);
  const [loadingPartnerCpfCnpj, setLoadingPartnerCpfCnpj] = useState(false);
  const [loadingPartner, setLoadingPartner] = useState(false);

  const [handleAddPartnerError, setHandleAddPartnerError] = useState('');
  const [partnersError, setPartnersError] = useState(false);

  const [cnpjSusepError, setCnpjSusepError] = useState(false);
  const [responsibleCpfSusepError, setResponsibleCpfSusepError] =
    useState(false);

  const [cnpjStatus, setCnpjStatus] = useState({
    status: statusCnpj,
    impeded: impededCnpj,
    firstCnae: firstCnae,
    secondCnaes: secondCnaes
  });

  const [susepStatus, setSusepStatus] = useState({
    status: statusSusep?.status,
    products: statusSusep?.products
  });

  const [responsibleSusepStatus, setResponsibleSusepStatus] = useState({
    status: statusSusep?.status,
    products: statusSusep?.products
  });

  const [partnersList, setPartnersList] = useState<Partner[]>(
    partners.filter((partner) => partner.cpfCnpj !== '')
  );

  const [isMailAddress, setIsMailAddress] = useState(isMailAddressState);

  const [modal, setModal] = useState({
    title: '',
    content: ''
  });

  const {
    isOpen: isOpenCnpjAlert,
    onOpen: onOpenCnpjAlert,
    onClose: onCloseCnpjAlert
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isEmpty = (values: FirstFormType) => {
    return (
      !Object.values(values) ||
      (!values.health &&
        !values.dentistry &&
        !values.lifeInsurance &&
        !values.travelInsurance &&
        !values.privateInsurance &&
        !values.investments) ||
      (Object.values(values).every((v) => !v) &&
        Object.getOwnPropertySymbols(values).length === 0)
    );
  };

  const hasErrors = (values: FirstFormType, errors: object) => {
    // values.isSimplesNacional === 'no'
    //   ? ((values.aliquot = ''), (values.acceptIsSimpleNacional = false))
    //   : values.aliquot;

    // values.isMailAddress ? clearIsMailAddress(values) : values.isMailAddress;

    return (
      Object.keys(errors).length > 0 || isEmpty(values) || !checkPartners()
    );
  };

  const clearAutomaticData = (values: FirstFormType) => {
    values.company = '';
    values.postalCode = '';
    values.address = '';
    values.city = '';
    values.district = '';
    values.state = '';
    values.number = '';
    values.complement = '';
    values.susep = '';
  };

  const clearIsMailAddress = (values: FirstFormType) => {
    values.mailPostalCode = '';
    values.mailAddress = '';
    values.mailCity = '';
    values.mailDistrict = '';
    values.mailState = '';
    values.mailNumber = '';
    values.mailComplement = '';
  };

  const checkCnpj = async (
    values: FirstFormType,
    setFieldError: (field: string, message: string) => void
  ) => {
    const cnpj = values.cnpj
      .replaceAll('.', '')
      .replace('-', '')
      .replace('/', '');

    if (firstStepState.cnpj !== values.cnpj) {
      setOnboardingId('');
    }

    const isValid = isCnpjValid(cnpj);

    if (cnpj.length === 14) {
      clearAutomaticData(values);

      setLoadingCnpj(true);

      if (!isValid) {
        setModal({
          title: 'Atenção',
          content: 'CNPJ inválido, favor verificar.'
        });

        clearAutomaticData(values);

        onOpen();

        setLoadingCnpj(false);

        return;
      }

      try {
        const { data: produtorData } = await api.get(
          `produtor/verify/${cnpj}?tipo_consulta=impedido`
        );

        if (produtorData.data.alreadyRegistered) {
          clearAutomaticData(values);

          onOpenCnpjAlert();

          setLoadingCnpj(false);

          return;
        }

        const { data: transunionData } = await api.get(
          `/cpfCnpj/transunion/${cnpj}`,
          {
            timeout: 7000
          }
        );

        const transunionStatus = transunionData.message
          ? transunionData.message
          : transunionData.SITUACAO_SRF?.DS_SITUACAO;

        if (
          transunionData?.SITUACAO_SRF?.DS_SITUACAO &&
          transunionData?.SITUACAO_SRF?.DS_SITUACAO !== 'ATIVA'
        ) {
          setModal({
            title: 'CNPJ inativo',
            content:
              'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
          });

          clearAutomaticData(values);

          onOpen();

          setLoadingCnpj(false);

          return;
        }

        const cdCnae = transunionData.message
          ? transunionData.message
          : transunionData.SITUACAO_SRF?.CD_CNAE;

        const cdSecondCnaes = transunionData.message
          ? transunionData.message
          : transunionData.CNAES_SECUNDARIOS.length > 0
          ? transunionData.CNAES_SECUNDARIOS.map(
              (cnae: {
                CD_CNAE_SECUNDARIO: string;
                DS_CNAE_SECUNDARIO: string;
              }) => cnae.CD_CNAE_SECUNDARIO
            )
          : [''];

        setCnpjStatus({
          status: transunionStatus,
          impeded: produtorData.data.impedido,
          firstCnae: cdCnae,
          secondCnaes: cdSecondCnaes
        });

        if (
          (await isValidCnae(cdCnae, transunionData.CNAES_SECUNDARIOS)) ===
          false
        ) {
          setModal({
            title: 'Atividade de corretagem de seguros não autorizada',
            content:
              'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
          });

          clearAutomaticData(values);

          onOpen();

          setLoadingCnpj(false);

          return;
        }

        try {
          const { data: susepData } = await api.get(`/cpfCnpj/susep/${cnpj}`, {
            timeout: 7000
          });

          if (susepData.retorno?.registros?.length) {
            const mandatoryProducts = [
              'Seguros de Pessoas',
              'Planos de Previdência Complementar'
            ];

            if (
              susepData.retorno?.registros[0]?.produtos?.includes(
                'Seguros de Danos'
              ) &&
              responsibleSusepStatus?.products?.length
            ) {
              if (
                responsibleSusepStatus.products?.includes(
                  'Seguros de Danos'
                ) === false
              ) {
                setModal({
                  title:
                    'Responsável técnico não possui o produto Seguros de Danos',
                  content:
                    'Por favor regularize a situação junto aos órgãos competentes ou troque o responsável para continuar o cadastro.'
                });

                clearAutomaticData(values);

                onOpen();

                setLoadingCnpj(false);

                return;
              }
            }

            const susepProducts = susepData.retorno?.registros[0]?.produtos;

            const includesMandatoryProducts = susepProducts
              ? mandatoryProducts.every((item) => susepProducts?.includes(item))
              : false;

            if (!includesMandatoryProducts) {
              setModal({
                title:
                  'Você deve ter os produtos Seguro de Vida e Planos de Previdência autorizados pela SUSEP',
                content:
                  'Por favor regularize a sua situação junto aos órgãos competentes e retorne para o processo de cadastro.'
              });

              clearAutomaticData(values);

              onOpen();

              setLoadingCnpj(false);

              return;
            }
          } else if (susepData.retorno?.registros[0]?.situacao !== 'Ativo') {
            setModal({
              title: 'Habilitação SUSEP inativa',
              content:
                'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
            });

            clearAutomaticData(values);

            onOpen();

            setLoadingCnpj(false);

            return;
          }

          setSusepStatus({
            status: susepData.retorno?.registros[0]?.situacao,
            products: susepData.retorno?.registros[0]?.produtos
          });

          values.susep = susepData.retorno?.registros[0]?.protocolo;

          const { data: cnpjValidate } = await api.get(`/cpfCnpj/${cnpj}`);

          if (
            !isValid ||
            cnpjValidate === 'CNPJ inválido' ||
            transunionStatus !== 'ATIVA'
          ) {
            setFieldError('cnpj', 'CNPJ inativo/inválido, favor verificar.');

            setLoadingCnpj(false);

            return;
          }
        } catch (error: any) {
          console.log(error);
          setCnpjSusepError(true);

          if (error?.response?.status === 429) {
            setModal({
              title: 'Ocorreu um erro',
              content:
                'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
            });

            onOpen();
          }
        }

        values.company = transunionData.SITUACAO_SRF?.NM_RAZAO_SOCIAL;
        values.postalCode = transunionData.SITUACAO_SRF?.ENDERECO.NR_CEP;
        values.address = transunionData.SITUACAO_SRF?.ENDERECO.NM_LOGRADOURO;
        values.city = transunionData.SITUACAO_SRF?.ENDERECO.NM_CIDADE;
        values.district = transunionData.SITUACAO_SRF?.ENDERECO.NM_BAIRRO;
        values.state = transunionData.SITUACAO_SRF?.ENDERECO.SG_ESTADO;
        values.number = transunionData.SITUACAO_SRF?.ENDERECO.NR_LOGRADOURO;
        values.complement =
          transunionData.SITUACAO_SRF?.ENDERECO.DS_COMPLEMENTO;
      } catch (error: any) {
        // setFieldError(
        //   'cnpj',
        //   'Ocorreu algum erro. Tente novamente em instantes'
        // );

        setLoadingCnpj(false);

        clearAutomaticData(values);

        if (error?.response?.status === 429) {
          setModal({
            title: 'Ocorreu um erro',
            content:
              'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
          });

          onOpen();
        }

        return;
      }
    }

    setLoadingCnpj(false);

    return;
  };

  const checkMailPostalCode = async (values: FirstFormType) => {
    const postalCode = values.mailPostalCode;

    if (postalCode.length === 9) {
      try {
        values.mailAddress = '';
        values.mailDistrict = '';
        values.mailState = '';
        values.mailCity = '';

        setLoadingMailPostalCode(true);

        const response = await api.get<any>(`/consulta_cep/${postalCode}`);

        values.mailAddress = response.data.logradouro;
        values.mailDistrict = response.data.bairro;
        values.mailState = response.data.uf;
        values.mailCity = response.data.localidade;

        setLoadingMailPostalCode(false);
      } catch (error: any) {
        values.mailPostalCode = '';

        if (error?.response?.status === 429) {
          setModal({
            title: 'Ocorreu um erro',
            content:
              'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
          });

          onOpen();
        }
      }
    }
  };

  const checkResponsible = async (
    values: FirstFormType,
    setFieldError: (field: string, message: string) => void
  ) => {
    const responsibleCpf = values.responsibleCpf
      .replaceAll('.', '')
      .replace('-', '');

    if (responsibleCpf.length === 11) {
      setLoadingCpf(true);

      values.responsibleSusep = '';

      try {
        const { data: produtorData } = await api.get(
          `produtor/verify/${responsibleCpf}?tipo_consulta=impedido,pep`
        );

        setFirstStepState({
          ...firstStepState,
          responsibleImpeded: produtorData.data.impedido,
          responsiblePep: produtorData.data.pep
          // responsibleImpeded: false
        });

        try {
          const { data: susepData } = await api.get(
            `/cpfCnpj/susep/${responsibleCpf}`,
            {
              timeout: 7000
            }
          );

          if (susepData.retorno?.registros?.length) {
            const mandatoryProducts = [
              'Seguros de Pessoas',
              'Planos de Previdência Complementar'
            ];

            const susepProducts = susepData.retorno?.registros[0]?.produtos;

            if (
              susepStatus?.products?.includes('Seguros de Danos') &&
              !susepProducts?.includes('Seguros de Danos')
            ) {
              setModal({
                title:
                  'Você deve ter o produto Seguros de Danos autorizados pela SUSEP',
                content:
                  'Por favor regularize a sua situação junto aos órgãos competentes e retorne para o processo de cadastro.'
              });

              onOpen();

              values.responsibleSusep = '';

              setLoadingCpf(false);

              return;
            }

            const includesMandatoryProducts = susepProducts
              ? mandatoryProducts.every((item) => susepProducts?.includes(item))
              : false;

            if (!includesMandatoryProducts) {
              setModal({
                title:
                  'Você deve ter os produtos Seguro de Vida e Planos de Previdência autorizados pela SUSEP',
                content:
                  'Por favor regularize a sua situação junto aos órgãos competentes e retorne para o processo de cadastro.'
              });

              onOpen();

              values.responsibleSusep = '';

              setLoadingCpf(false);

              return;
            }
          } else if (susepData.retorno?.registros[0]?.situacao !== 'Ativo') {
            setModal({
              title: 'Habilitação SUSEP inativa',
              content:
                'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
            });

            onOpen();

            values.responsibleSusep = '';

            setLoadingCpf(false);

            return;
          }

          setResponsibleSusepStatus({
            status: susepData.retorno?.registros[0]?.situacao,
            products: susepData.retorno?.registros[0]?.produtos
          });

          if (susepData.retorno?.registros[0]?.situacao !== 'Ativo') {
            setModal({
              title: 'Habilitação SUSEP inativa',
              content:
                'Por favor regularize a sua situação junto aos órgãos competentes e volte aqui para se cadastrar.'
            });

            onOpen();

            values.responsibleSusep = '';

            setLoadingCpf(false);

            return;
          } else {
            values.responsibleSusep =
              susepData.retorno?.registros[0]?.protocolo;

            setLoadingCpf(false);

            return;
          }
        } catch (error: any) {
          console.log(error);

          setResponsibleCpfSusepError(true);
          setLoadingCpf(false);

          if (error?.response?.status === 429) {
            setModal({
              title: 'Ocorreu um erro',
              content:
                'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
            });

            onOpen();
          }
        }
      } catch (error: any) {
        setFieldError(
          'responsibleCpf',
          'Ocorreu algum erro. Tente novamente em instantes'
        );

        setLoadingCpf(false);

        if (error?.response?.status === 429) {
          setModal({
            title: 'Ocorreu um erro',
            content:
              'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
          });

          onOpen();
        }
      }
    }
  };

  const isValidCnae = async (
    cdCnae: number,
    cnaesSecundarios: Array<{ CD_CNAE_SECUNDARIO: string }>
  ) => {
    if (cdCnae === 6622300) return true;

    if (cnaesSecundarios?.find((cnae) => cnae.CD_CNAE_SECUNDARIO === '6622300'))
      return true;

    return false;
  };

  const checkPartnerCpfCnpj = async (values: FirstFormType) => {
    const partnerCpfCnpj = values.partnerCpfCnpj
      .replaceAll('.', '')
      .replace('/', '')
      .replace('-', '');

    if (
      partnerCpfCnpj.toString().length === 11 ||
      partnerCpfCnpj.toString().length === 14
    ) {
      setLoadingPartnerCpfCnpj(true);

      try {
        const { data: susepData } = await api.get(
          `/cpfCnpj/susep/${partnerCpfCnpj.toString()}`,
          {
            timeout: 7000
          }
        );

        if (susepData.retorno?.registros[0]?.protocolo) {
          values.partnerSusep = susepData.retorno?.registros[0]?.protocolo;
        } else values.partnerSusep = '';

        setLoadingPartnerCpfCnpj(false);
      } catch (error: any) {
        console.log(error);

        setLoadingPartnerCpfCnpj(false);

        if (error?.response?.status === 429) {
          setModal({
            title: 'Ocorreu um erro',
            content:
              'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
          });

          onOpen();
        }
      }
    }
  };

  const handleAddPartner = async (
    name: string,
    cpfCnpj: string,
    percentage: string,
    susep: string,
    errorName: string | undefined,
    errorCpfCnpj: string | undefined,
    errorPercentage: string | undefined,
    values: FirstFormType,
    setFieldError: (field: string, message: string) => void
  ) => {
    if (
      !values.partnerName ||
      !values.partnerCpfCnpj ||
      (values.partnerCpfCnpj
        .replaceAll('.', '')
        .replace('/', '')
        .replace('-', '').length > 11 &&
        values.partnerCpfCnpj
          .replaceAll('.', '')
          .replace('/', '')
          .replace('-', '').length < 14) ||
      !values.partnerPercentage ||
      errorName ||
      errorCpfCnpj ||
      errorPercentage
    ) {
      (!values.partnerName || errorName) &&
        setFieldError('partnerName', 'Favor preencher');
      (!values.partnerCpfCnpj ||
        errorCpfCnpj ||
        (values.partnerCpfCnpj
          .replaceAll('.', '')
          .replace('/', '')
          .replace('-', '').length > 11 &&
          values.partnerCpfCnpj
            .replaceAll('.', '')
            .replace('/', '')
            .replace('-', '').length < 14)) &&
        setFieldError('partnerCpfCnpj', 'Favor verificar o preenchimento');
      (!values.partnerPercentage || errorPercentage) &&
        setFieldError('partnerPercentage', 'Favor preencher');
      Number(values.partnerPercentage) > 100 &&
        setFieldError('partnerPercentage', 'Valor superior a 100%');

      return;
    }

    const foundPartner = partnersList.find(
      (partner) => partner.cpfCnpj === cpfCnpj
    );

    if (foundPartner) {
      setFieldError('partnerCpfCnpj', 'CPF ou CNPJ já inserido');

      return;
    }

    const sum = partnersList.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.percentage);
    }, 0);

    if (parseFloat(values.partnerPercentage.replace(',', '.')) + sum > 100) {
      setFieldError('partnerPercentage', 'Valor somado ultrapassando 100%');

      return;
    }

    setLoadingPartner(true);
    setHandleAddPartnerError('');

    try {
      const { data: produtorData } = await api.get(
        `produtor/verify/${cpfCnpj}?tipo_consulta=impedido,pep`,
        {
          timeout: 7000
        }
      );

      try {
        const { data: transunionData } = await api.get(
          `/cpfCnpj/transunion/${cpfCnpj}`,
          {
            timeout: 7000
          }
        );

        const partnerStatus = transunionData.message
          ? transunionData.message
          : cpfCnpj.length > 11
          ? transunionData.SITUACAO_SRF?.DS_SITUACAO
          : transunionData.SITUACAO_SRF?.DS_SRF_PF;

        values.partnerName = '';
        values.partnerCpfCnpj = '';
        values.partnerPercentage = '';
        values.partnerSusep = '';

        setPartnersList([
          ...partnersList,
          {
            name,
            cpfCnpj,
            impeded: produtorData.data ? produtorData.data.impedido : false,
            pep: produtorData.data ? produtorData.data.pep : false,
            // impeded: false,
            percentage,
            susep: susep,
            status: partnerStatus ? partnerStatus : 'INATIVA'
          }
        ]);

        setLoadingPartner(false);
      } catch (error) {
        values.partnerName = '';
        values.partnerCpfCnpj = '';
        values.partnerPercentage = '';
        values.partnerSusep = '';

        setPartnersList([
          ...partnersList,
          {
            name,
            cpfCnpj,
            impeded: false,
            pep: false,
            // impeded: false,
            percentage,
            susep: susep,
            status: 'INDISPONIVEL'
          }
        ]);

        setLoadingPartner(false);
      }
    } catch (error: any) {
      console.log(error);
      setLoadingPartner(false);
      setHandleAddPartnerError('Ocorreu um erro, favor adicionar novamente.');

      if (error?.response?.status === 429) {
        setModal({
          title: 'Ocorreu um erro',
          content:
            'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
        });

        onOpen();
      }
    }
  };

  const handleRemovePartner = (cpfCnpj: string) => {
    setPartnersList(
      partnersList.filter((partner) => partner.cpfCnpj !== cpfCnpj)
    );
  };

  const checkPartners = () => {
    const sum = partnersList.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.percentage);
    }, 0);

    sum === 100 && setPartnersError(false);

    return sum === 100 ? true : false;
  };

  const handleSubmit = async (values: FirstFormType) => {
    if (!checkPartners()) {
      const selector = `[name=partnerName]`;
      const errorElement = document.querySelector(selector) as HTMLElement;
      if (errorElement) {
        errorElement.focus();
      }

      setPartnersError(true);

      return;
    }

    setLoading(true);

    try {
      const crm = {
        status:
          values.isAdvice === 'yes' ? '' : 'Aguardando complemento de cadastro',
        owner: values.isAdvice === 'yes' ? '' : 'Em cadastro'
      };

      // console.log({
      //   onboardingId,
      //   cnpj: values.cnpj.replaceAll('.', '').replace('/', '').replace('-', ''),
      //   company: values.company,
      //   statusCnpj: cnpjStatus.status,
      //   impeded: cnpjStatus.impeded,
      //   firstCnae: cnpjStatus.firstCnae,
      //   secondCnaes: cnpjStatus.secondCnaes,
      //   susep: {
      //     number: values.susep,
      //     status: susepStatus.status,
      //     products: susepStatus.products
      //   },
      //   address: {
      //     postalCode: values.postalCode.toString(),
      //     city: values.city,
      //     district: values.district,
      //     state: values.state,
      //     street: values.address,
      //     number: values.number,
      //     complement: values.complement
      //   },
      //   isMailAddress: values.isMailAddress,
      //   mailAddress: {
      //     postalCode: values.mailPostalCode,
      //     city: values.mailCity,
      //     district: values.mailDistrict,
      //     state: values.mailState,
      //     street: values.mailAddress,
      //     number: values.mailNumber,
      //     complement: values.mailComplement
      //   },
      //   phone1: values.phone1,
      //   frame1: values.frame1,
      //   phone2: values.phone2,
      //   frame2: values.frame2,
      //   email: values.email,
      //   isAdvice: values.isAdvice === 'yes' ? true : false,
      //   // advice,
      //   responsible: {
      //     name: values.responsible,
      //     cpf: values.responsibleCpf.replaceAll('.', '').replace('-', ''),
      //     susep: {
      //       number: values.responsibleSusep,
      //       status: responsibleSusepStatus.status
      //         ? responsibleSusepStatus.status
      //         : '',
      //       products: responsibleSusepStatus.products
      //         ? responsibleSusepStatus.products
      //         : ''
      //     },
      //     impeded: responsibleImpeded,
      //     pep: responsiblePep
      //   },
      //   productType: {
      //     health: values.health,
      //     dentistry: values.dentistry,
      //     lifeInsurance: values.lifeInsurance,
      //     travelInsurance: values.travelInsurance,
      //     privateInsurance: values.privateInsurance,
      //     investments: values.investments
      //   },
      //   isSimplesNacional: values.isSimplesNacional === 'yes' ? true : false,
      //   aliquot:
      //     values.isSimplesNacional === 'yes'
      //       ? values.aliquot.replace(',', '.')
      //       : '',
      //   acceptIsSimpleNacional: values.acceptIsSimpleNacional,
      //   partners: partnersList,
      //   crm
      // });

      const response = await api.post('/signup/cnpj/first-step', {
        onboardingId,
        cnpj: values.cnpj.replaceAll('.', '').replace('/', '').replace('-', ''),
        company: values.company,
        statusCnpj: cnpjStatus.status,
        impeded: cnpjStatus.impeded,
        firstCnae: cnpjStatus.firstCnae.toString(),
        secondCnaes: cnpjStatus.secondCnaes,
        susep: {
          number: values.susep,
          status: susepStatus.status ? susepStatus.status : '',
          products: susepStatus.products ? susepStatus.products : ''
        },
        address: {
          postalCode: values.postalCode.toString(),
          city: values.city,
          district: values.district,
          state: values.state,
          street: values.address,
          number: values.number,
          complement: values?.complement ?? ''
        },
        isMailAddress: values.isMailAddress,
        mailAddress: {
          postalCode: values.mailPostalCode,
          city: values.mailCity,
          district: values.mailDistrict,
          state: values.mailState,
          street: values.mailAddress,
          number: values.mailNumber,
          complement: values.mailComplement
        },
        phone1: values.phone1,
        frame1: values.frame1,
        phone2: values.phone2,
        frame2: values.frame2,
        email: values.email,
        isAdvice: values.isAdvice === 'yes' ? true : false,
        // advice,
        responsible: {
          name: values.responsible,
          cpf: values.responsibleCpf.replaceAll('.', '').replace('-', ''),
          susep: {
            number: values.responsibleSusep,
            status: responsibleSusepStatus.status
              ? responsibleSusepStatus.status
              : '',
            products: responsibleSusepStatus.products
              ? responsibleSusepStatus.products
              : ''
          },
          impeded: responsibleImpeded,
          pep: responsiblePep
        },
        productType: {
          health: values.health,
          dentistry: values.dentistry,
          lifeInsurance: values.lifeInsurance,
          travelInsurance: values.travelInsurance,
          privateInsurance: values.privateInsurance,
          investments: values.investments
        },
        isSimplesNacional: values.isSimplesNacional === 'yes' ? true : false,
        aliquot:
          values.isSimplesNacional === 'yes'
            ? values.aliquot.replace(',', '.')
            : '',
        acceptIsSimpleNacional: values.acceptIsSimpleNacional,
        partners: partnersList,
        crm
      });

      if (response.data.response) {
        setModal({
          title: 'Seu cadastro está em análise',
          content: 'Por favor, aguarde retorno através do email.'
        });

        onOpen();

        setLoading(false);

        return;
      }

      setOnboardingId(response.data.corretora.Id);

      const state = {
        cnpj: values.cnpj,
        company: values.company,
        statusCnpj: cnpjStatus.status,
        impededCnpj: cnpjStatus.impeded,
        firstCnae: cnpjStatus.firstCnae,
        secondCnaes: cnpjStatus.secondCnaes,
        postalCode: values.postalCode,
        address: values.address,
        number: values.number,
        district: values.district,
        city: values.city,
        state: values.state,
        complement: values.complement,
        isMailAddress: values.isMailAddress,
        mailPostalCode: values.mailPostalCode,
        mailAddress: values.mailAddress,
        mailNumber: values.mailNumber,
        mailDistrict: values.mailDistrict,
        mailCity: values.mailCity,
        mailState: values.mailState,
        mailComplement: values.mailComplement,
        susep: values.susep,
        phone1: values.phone1,
        frame1: values.frame1,
        phone2: values.phone2,
        frame2: values.frame2,
        email: values.email,
        isAdvice: values.isAdvice,
        advice: values.advice,
        responsible: values.responsible,
        responsibleCpf: values.responsibleCpf,
        responsibleSusep: values.responsibleSusep,
        responsibleImpeded: responsibleImpeded,
        responsiblePep: responsiblePep,
        health: values.health,
        dentistry: values.dentistry,
        lifeInsurance: values.lifeInsurance,
        travelInsurance: values.travelInsurance,
        privateInsurance: values.privateInsurance,
        investments: values.investments,
        partnerName: values.partnerName,
        partnerCpfCnpj: values.partnerCpfCnpj,
        partnerPercentage: values.partnerPercentage,
        partnerSusep: values.partnerSusep,
        partners: partnersList,
        isSimplesNacional: values.isSimplesNacional,
        aliquot: values.aliquot,
        acceptIsSimpleNacional: values.acceptIsSimpleNacional,
        statusSusep: {
          status: susepStatus.status,
          products: susepStatus.products
        }
      };

      setFirstStepState(state);

      setStepState(2);

      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);

      if (error?.response?.status === 429) {
        setModal({
          title: 'Ocorreu um erro',
          content:
            'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
        });

        onOpen();
      }
    }
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={firstStepState}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={firstStepValidation}
      >
        {({ handleSubmit, values, errors, setFieldError }): JSX.Element => (
          <Flex
            flexDir="column"
            as="form"
            flexWrap="wrap"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={handleSubmit as any}
          >
            <ErrorFocus />
            <MainContent>
              {/* <StepTimeLine step={stepState} /> */}
              {isWideVersion && <StepTimeLine2 step={stepState} />}

              <Flex
                align="center"
                flexDir="column"
                alignSelf="center"
                flex=".85"
                mt={isWideVersion ? '0' : '5rem'}
              >
                <Stack mb="2.5rem" spacing="0.8rem">
                  <Text fontSize="1.2rem">
                    Agora, por favor, preencha os dados abaixo:
                  </Text>
                  <Text fontSize=".8rem">* Campos obrigatórios</Text>
                </Stack>
                <Stack
                  spacing="2rem"
                  pointerEvents={
                    loadingCnpj ||
                    loadingMailPostalCode ||
                    loadingCpf ||
                    loadingPartnerCpfCnpj ||
                    loadingPartner
                      ? 'none'
                      : 'all'
                  }
                  opacity={
                    loadingCnpj ||
                    loadingMailPostalCode ||
                    loadingCpf ||
                    loadingPartnerCpfCnpj ||
                    loadingPartner
                      ? '0.4'
                      : '1'
                  }
                >
                  <InputsContainer w="25rem">
                    <Box>
                      <FormMaskedInput
                        label="CNPJ *"
                        name="cnpj"
                        mask="cnpj"
                        value={values.cnpj}
                        onChange={(e) => {
                          values.cnpj = e.target.value;
                          checkCnpj(values, setFieldError);
                        }}
                        loading={loadingCnpj}
                      />

                      {/* <FormInput
                          label="CNPJ *"
                          name="cnpj"
                          onKeyUp={() => checkCnpj(values, setFieldError)}
                          loading={loadingCnpj}
                        /> */}
                      <FormInput
                        name="company"
                        // isReadOnly
                        label="Razão Social *"
                        loading={loadingCnpj}
                      />
                      <FormInput
                        name="susep"
                        // isReadOnly={!cnpjSusepError}
                        label="Habilitação SUSEP *"
                        loading={loadingCnpj}
                      />
                    </Box>
                    <Box mb="1rem">
                      <Flex>
                        <Box mr="3.5rem">
                          <FormMaskedInput
                            name="phone1"
                            mask="phone"
                            label="Celular *"
                          />
                        </Box>
                        {/* <Box w="40%">
                          <FormInput name="frame1" label="Ramal" />
                        </Box> */}
                      </Flex>
                      <Flex>
                        <Box mr="3.5rem">
                          <FormMaskedInput
                            mask="phone"
                            name="phone2"
                            label="Telefone 2"
                          />
                        </Box>
                        <Box w="40%">
                          <FormInput
                            name="frame2"
                            label="Ramal"
                            maxLength={4}
                          />
                        </Box>
                      </Flex>
                      <FormInput name="email" label="E-mail *" />
                    </Box>
                    <Box>
                      <FormInput
                        name="responsible"
                        label="Nome do Responsável Técnico pela Corretora *"
                        mb="1rem"
                      />
                      <FormMaskedInput
                        name="responsibleCpf"
                        mask="cpf"
                        label="CPF *"
                        value={values.responsibleCpf}
                        onChange={(e) => {
                          values.responsibleCpf = e.target.value;
                          checkResponsible(values, setFieldError);
                        }}
                        loading={loadingCpf}
                      />
                      <FormInput
                        name="responsibleSusep"
                        label="Habilitação SUSEP *"
                        loading={loadingCpf}
                        // isReadOnly={!responsibleCpfSusepError}
                        mb="1rem"
                      />
                    </Box>
                  </InputsContainer>

                  <InputsContainer w="25rem">
                    <Box>
                      <FormInput
                        name="postalCode"
                        // isReadOnly
                        label="CEP *"
                        loading={loadingCnpj}
                      />

                      <FormInput
                        name="address"
                        // isReadOnly
                        label="Endereço *"
                        loading={loadingCnpj}
                      />
                      <Flex>
                        <Box>
                          <FormInput
                            name="number"
                            // isReadOnly
                            label="Número *"
                            loading={loadingCnpj}
                            mr="2.5rem"
                          />
                        </Box>
                        <Box w="120px">
                          <FormInput
                            name="complement"
                            // isReadOnly
                            label="Complemento"
                            loading={loadingCnpj}
                          />
                        </Box>
                      </Flex>
                      <FormInput
                        name="district"
                        // isReadOnly
                        label="Bairro *"
                        loading={loadingCnpj}
                      />
                      <FormInput
                        name="city"
                        // isReadOnly
                        label="Cidade *"
                        loading={loadingCnpj}
                      />
                      <FormSelect
                        name="state"
                        label="Estado *"
                        selectProps={{
                          placeholder: 'Selecione...'
                        }}
                      >
                        {states.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </FormSelect>
                    </Box>

                    <Flex align="center">
                      <SwitchControl
                        name="isMailAddress"
                        switchProps={{ size: 'lg' }}
                        onChange={() => {
                          setIsMailAddress(values.isMailAddress);
                          if (values.isMailAddress) {
                            clearIsMailAddress(values);
                          }
                        }}
                        mr=".8rem"
                      />
                      <Text fontSize=".9rem">
                        Desejo utilizar esse mesmo endereço para correspondência
                      </Text>
                    </Flex>

                    {!values.isMailAddress && (
                      <Box mt="2rem">
                        <Text fontWeight="bold" mb="0.3rem">
                          Endereço de Correspondência
                        </Text>
                        <FormMaskedInput
                          mask="postalCode"
                          name="mailPostalCode"
                          value={values.mailPostalCode}
                          onChange={(e) => {
                            values.mailPostalCode = e.target.value;
                            checkMailPostalCode(values);
                          }}
                          label="CEP *"
                        />
                        <FormInput name="mailAddress" label="Endereço *" />
                        <Flex>
                          <FormInput
                            name="mailNumber"
                            label="Número *"
                            mr="2.5rem"
                          />
                          <Box>
                            <FormInput
                              name="mailComplement"
                              label="Complemento"
                            />
                          </Box>
                        </Flex>
                        <FormInput
                          name="mailDistrict"
                          loading={loadingMailPostalCode}
                          label="Bairro *"
                        />
                        <FormInput
                          name="mailCity"
                          loading={loadingMailPostalCode}
                          label="Cidade *"
                        />
                        <FormSelect
                          name="mailState"
                          label="Estado *"
                          selectProps={{
                            placeholder: 'Selecione...'
                          }}
                        >
                          {states.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </FormSelect>
                      </Box>
                    )}
                  </InputsContainer>

                  <InputsContainer w="25rem">
                    <Flex flexDir="column" mb="3.5rem">
                      {partnersError && (
                        <Text
                          textAlign="center"
                          color="red.500"
                          fontSize="sm"
                          mb=".5rem"
                        >
                          Favor adicionar os sócios até a soma de participação
                          completar 100% (Preencha e clique no botão ADICIONAR
                          SÓCIO a baixo)
                        </Text>
                      )}
                      <Box
                        pointerEvents={
                          checkPartners() ||
                          loadingCnpj ||
                          loadingMailPostalCode ||
                          loadingCpf ||
                          loadingPartnerCpfCnpj ||
                          loadingPartner
                            ? 'none'
                            : 'all'
                        }
                        opacity={
                          checkPartners() ||
                          loadingCnpj ||
                          loadingMailPostalCode ||
                          loadingCpf ||
                          loadingPartnerCpfCnpj ||
                          loadingPartner
                            ? '0.4'
                            : '1'
                        }
                      >
                        <FormInput
                          name="partnerName"
                          label="Nome do Sócio *"
                          loading={loadingPartnerCpfCnpj}
                        />
                        <FormMaskedInput
                          name="partnerCpfCnpj"
                          label="CPF / CNPJ *"
                          mask={
                            values.partnerCpfCnpj.length <= 14
                              ? 'cpfCnpj'
                              : 'cnpj'
                          }
                          loading={loadingPartnerCpfCnpj}
                          readOnly={loadingPartnerCpfCnpj}
                          value={values.partnerCpfCnpj}
                          onChange={(e) => {
                            values.partnerCpfCnpj = e.target.value;
                            checkPartnerCpfCnpj(values);
                          }}
                        />
                        <FormMaskedInput
                          mask="threeDecimal"
                          name="partnerPercentage"
                          readOnly={loadingPartnerCpfCnpj}
                          loading={loadingPartnerCpfCnpj}
                          label="% de Participação na Empresa *"
                        />
                        <FormInput
                          name="partnerSusep"
                          label="Habilitação SUSEP"
                          loading={loadingPartnerCpfCnpj}
                        />
                      </Box>
                      <Text color="red.500">{handleAddPartnerError}</Text>
                      <Button
                        onClick={() =>
                          handleAddPartner(
                            values.partnerName,
                            values.partnerCpfCnpj
                              .replaceAll('.', '')
                              .replace('/', '')
                              .replace('-', ''),
                            values.partnerPercentage.replace(',', '.'),
                            values.partnerSusep,
                            errors.partnerName,
                            errors.partnerCpfCnpj,
                            errors.partnerPercentage,
                            values,
                            setFieldError
                          )
                        }
                        variant="ghost"
                        color="#FF5000"
                        isDisabled={checkPartners()}
                        leftIcon={
                          loadingPartner ? (
                            <Spinner />
                          ) : (
                            <MdOutlineAddCircleOutline size={25} />
                          )
                        }
                      >
                        {loadingPartner ? '' : 'ADICIONAR SÓCIO'}
                      </Button>

                      {partnersList.length > 0 && (
                        <Box mt="1rem">
                          {partnersList.map((partner) => (
                            <Stack key={partner.cpfCnpj}>
                              <Button
                                variant="ghost"
                                onClick={() =>
                                  handleRemovePartner(partner.cpfCnpj)
                                }
                                title="Remover"
                                colorScheme="red"
                                ml="auto"
                                mb="0"
                              >
                                <RiDeleteBinLine size={20} />
                              </Button>
                              <Box w="100%" mt="0">
                                <FormLabel
                                  htmlFor={partner.name}
                                  color="#828282"
                                  fontSize=".75rem"
                                  m="0"
                                  p="0"
                                >
                                  Nome do Sócio
                                </FormLabel>
                                <Input
                                  readOnly
                                  borderColor="gray.800"
                                  variant="flushed"
                                  label="Nome do Sócio"
                                  value={partner.name}
                                  mb="2rem"
                                />
                                <FormLabel
                                  htmlFor={partner.cpfCnpj}
                                  color="#828282"
                                  fontSize=".75rem"
                                  m="0"
                                  p="0"
                                >
                                  CPF / CNPJ
                                </FormLabel>
                                <Input
                                  readOnly
                                  borderColor="gray.800"
                                  variant="flushed"
                                  label="CPF / CNPJ *"
                                  value={partner.cpfCnpj}
                                  mb="2rem"
                                />
                                <FormLabel
                                  htmlFor={partner.percentage}
                                  color="#828282"
                                  fontSize=".75rem"
                                  m="0"
                                  p="0"
                                >
                                  % de Participação na Empresa
                                </FormLabel>
                                <Input
                                  readOnly
                                  borderColor="gray.800"
                                  variant="flushed"
                                  label="% de Participação na Empresa *"
                                  value={partner.percentage}
                                  mb="2rem"
                                />
                                <FormLabel
                                  htmlFor={partner.susep}
                                  color="#828282"
                                  fontSize=".75rem"
                                  m="0"
                                  p="0"
                                >
                                  Habilitação SUSEP
                                </FormLabel>
                                <Input
                                  readOnly
                                  borderColor="gray.800"
                                  variant="flushed"
                                  label="Habilitação SUSEP"
                                  value={partner.susep}
                                  mb="1.5rem"
                                />
                              </Box>
                            </Stack>

                            // <Stack
                            //   key={partner.id}
                            //   bg="orange.500"
                            //   borderRadius="1rem"
                            //   fontWeight="bold"
                            //   textAlign="center"
                            //   m=".5rem"
                            // >
                            //   <Flex flexDir="column" h="11rem" justify="center">
                            //     <Flex flexDir="column" alignItems="center" mt=".6rem">
                            //       <Text fontSize="0.9rem">{partner.name}</Text>

                            //       <FaHandshake size="6rem" />
                            //       <Text fontSize="0.9rem">{partner.percentage}%</Text>
                            //     </Flex>
                            //     <Button
                            //       onClick={() => handleRemovePartner(partner.id)}
                            //       colorScheme="red"
                            //       mt="auto"
                            //     >
                            //       <MdRemoveCircle />
                            //       <Text fontSize="0.7rem">REMOVER SÓCIO</Text>
                            //     </Button>
                            //   </Flex>
                            // </Stack>
                          ))}
                        </Box>
                      )}
                    </Flex>
                    <Flex mb="2.5rem">
                      <Box>
                        <FormLabel fontWeight="bold">
                          Você trabalha em parceria com Assessoria de Seguros? *
                        </FormLabel>
                        <RadioGroupControl name="isAdvice" mb="1rem">
                          <Flex justify="space-between" w="75%">
                            <Radio value="yes">Sim</Radio>
                            <Radio value="no">Não</Radio>
                          </Flex>
                        </RadioGroupControl>

                        {/* {values.isAdvice === 'yes' && (
				<>
                  <FormLabel>Indique a Assessoria que você trabalha</FormLabel>
                  <SelectControl
                    name="advice"
                    selectProps={{ placeholder: 'Selecione a assessoria' }}
                  >
                    <option value="option1">Opção 1</option>
                    <option value="option2">Opção 2</option>
                    <option value="option3">Opção 3</option>
                  </SelectControl>
                </>
              )} */}
                      </Box>
                      <Box>
                        <Tooltip
                          shouldWrapChildren
                          bg="#1F1E1F"
                          color="white"
                          label="A Assessoria de Seguros é o elo entre o pequeno e médio corretor e as Seguradoras e Operadoras. A Assessoria presta serviço de atendimento ao corretor de forma personalizada, conforme suas necessidades, oferece suporte operacional, comercial e técnico, treinamentos para atualizações do mercado e de produtos e muitas outras facilidades."
                        >
                          <FiInfo size={30} />
                        </Tooltip>
                      </Box>
                    </Flex>
                    <Box>
                      <FormLabel fontWeight="bold">
                        A sua Corretora é optante pelo Simples Nacional? *
                      </FormLabel>
                      <RadioGroupControl name="isSimplesNacional" mb="1rem">
                        <Flex justify="space-between" w="68%">
                          <Radio value="yes">
                            <Text>Sim</Text>
                          </Radio>
                          <HStack onClick={() => (values.aliquot = '')}>
                            <Radio value="no">Não</Radio>
                          </HStack>
                        </Flex>
                      </RadioGroupControl>
                      <Box
                        pointerEvents={
                          values.isSimplesNacional === 'yes' ? 'all' : 'none'
                        }
                        opacity={
                          values.isSimplesNacional === 'yes' ? '1' : '0.4'
                        }
                      >
                        <FormLabel fontWeight="bold">
                          Qual a sua alíquota do ISS? *
                        </FormLabel>
                        <Box w="3rem">
                          <FormMaskedInput
                            label=""
                            name="aliquot"
                            mask="oneDecimal"
                            placeholder="%"
                          />
                        </Box>
                      </Box>
                      {values.isSimplesNacional === 'yes' && (
                        <Flex>
                          <CheckboxSingleControl
                            name="acceptIsSimpleNacional"
                            checkBoxProps={{
                              borderColor: '#0B234C',
                              size: 'lg'
                            }}
                          />
                          <Text fontSize="1rem">
                            Declaro que a informação prestada é da minha
                            responsabilidade, bem como declaro e assumo o risco
                            de informar imediatamente o eventual
                            desenquadramento ou alteração de regime, a mudança
                            na faixa do seu faturamento que implique alteração
                            da alíquota do ISS. Estou ciente de que a falsidade
                            na prestação destas informações ou a omissão em
                            informar suas alterações estará sujeita a
                            penalidades legais.
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  </InputsContainer>

                  <InputsContainer w="25rem">
                    <FormLabel htmlFor="products">
                      Selecione abaixo o(s) produto(s) que você quer se
                      cadastrar para comercializar com a SulAmérica *
                    </FormLabel>

                    <Stack>
                      <CheckboxSingleControl
                        name="health"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Saúde
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="dentistry"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Odonto
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="travelInsurance"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Seguro Viagem
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="lifeInsurance"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Seguro de Vida
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="privateInsurance"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Previdência Privada
                      </CheckboxSingleControl>
                      <CheckboxSingleControl
                        name="investments"
                        checkBoxProps={{ borderColor: '#0B234C', size: 'lg' }}
                      >
                        Investimentos
                      </CheckboxSingleControl>
                    </Stack>
                  </InputsContainer>
                </Stack>
              </Flex>
            </MainContent>

            <FooterContainer>
              <HStack
                h="100%"
                spacing={{ base: '0', md: '10', lg: '10' }}
                pointerEvents={
                  loadingCnpj ||
                  loadingMailPostalCode ||
                  loadingCpf ||
                  loadingPartnerCpfCnpj ||
                  loadingPartner
                    ? 'none'
                    : 'all'
                }
                opacity={
                  loadingCnpj ||
                  loadingMailPostalCode ||
                  loadingCpf ||
                  loadingPartnerCpfCnpj ||
                  loadingPartner
                    ? '0.4'
                    : '1'
                }
                flexWrap="wrap"
                justify="center"
                textAlign="center"
              >
                <Link href="/">
                  <CustomButton variant="outline">VOLTAR</CustomButton>
                </Link>
                <CustomButton type="submit" isLoading={loading}>
                  AVANÇAR
                </CustomButton>
              </HStack>
            </FooterContainer>
          </Flex>
        )}
      </Formik>

      <CustomModal
        isOpen={isOpenCnpjAlert}
        title="CNPJ já cadastrado"
        onClose={onCloseCnpjAlert}
        content={
          <Text>
            Encontramos um cadastro nesse CNPJ. Clique aqui para ser
            redirecionado ao Canais de Atendimento.
          </Text>
        }
        buttons={
          <>
            <Stack spacing={6}>
              <Link href="https://portal.sulamericaseguros.com.br/canaisdeatendimento/">
                <CustomButton>CANAIS DE ATENDIMENTO</CustomButton>
              </Link>
              <Link href="/">
                <CustomButton variant="outline" onClick={onClose}>
                  VOLTAR PARA HOME
                </CustomButton>
              </Link>
            </Stack>
          </>
        }
      />

      <CustomModal
        isOpen={isOpen}
        title={modal.title}
        onClose={onClose}
        content={<Text>{modal.content}</Text>}
        buttons={
          <>
            <Stack>
              <Link href="/">
                <CustomButton variant="outline" onClick={onClose}>
                  VOLTAR PARA A HOME
                </CustomButton>
              </Link>
            </Stack>
          </>
        }
      />
    </>
  );
};
