import {
  Box,
  Flex,
  FormLabel,
  HStack,
  Input,
  Radio,
  Stack,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { RadioGroupControl } from 'formik-chakra-ui';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CustomButton } from '../../../../components/actions/CustomButton';
import { FormInput } from '../../../../components/fields/FormInput';

import { FormSelect } from '../../../../components/fields/FormSelect';
import { FooterContainer } from '../../../../components/layouts/FooterContainer';
import { InputsContainer } from '../../../../components/layouts/InputsContainer';
import { MainContent } from '../../../../components/layouts/MainContent';
import { ErrorFocus } from '../../../../components/widgets/ErrorFocus';
import { StepTimeLine2 } from '../../../../components/widgets/StepTimeline2/StepTimeLine';
import CpfFormContext from '../../../../contexts/CpfFormContext';
import { SecondFormType } from '../../../../types/formTypesCpf';
import { secondStepValidation } from '../Validations/CpfForm';
import { insurers } from '../Mocks';
import { FormMaskedInput } from '../../../../components/fields/FormMaskedInput';
import { currency } from '../../../../utils/masks';

export const SecondForm = (): JSX.Element => {
  const {
    stepState,
    setStepState,
    firstStepState,
    secondStepState,
    setSecondStepState
  } = useContext(CpfFormContext);

  const [loading, setLoading] = useState(false);

  const clearExactIncome = (
    values: SecondFormType,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (values.estimatedIncome !== 'R$ 6.000,01 ou superior') {
      setFieldValue('exactIncome', '');
    }
  };

  const handleSubmit = (values: SecondFormType) => {
    setLoading(true);
    const { isAdvice } = firstStepState;

    const crm = {
      status:
        isAdvice === 'yes'
          ? 'Aguardando cadastro'
          : 'Aguardando definição da EA',
      owner: isAdvice === 'yes' ? 'Área de cadastro' : 'Agente comercial'
    };

    const data = Object.assign(values, crm);

    setTimeout(() => {
      setSecondStepState(values);

      setStepState(3);

      setLoading(false);
    }, 2000);
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <Formik
        initialValues={secondStepState}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={secondStepValidation}
      >
        {({ handleSubmit, values, errors, setFieldValue }): JSX.Element => (
          <Flex
            flexDir="column"
            as="form"
            flexWrap="wrap"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSubmit={handleSubmit as any}
          >
            <ErrorFocus />
            <MainContent>
              {isWideVersion && <StepTimeLine2 step={stepState} />}

              <Flex
                w="100%"
                align="center"
                flexDir="column"
                flex=".85"
                mt={isWideVersion ? '0' : '5rem'}
              >
                <Stack mb="2.5rem" spacing="0.8rem" w="25rem">
                  <Text fontSize="1.2rem">
                    Agora, por favor, preencha os dados abaixo:
                  </Text>
                  <Text fontSize=".8rem">
                    * Todos os campos que não estiverem demarcados como
                    (Opcional) são obrigatórios o preenchimento.
                  </Text>
                </Stack>
                <Stack spacing="2rem">
                  <InputsContainer w="25rem">
                    <Box>
                      <FormLabel fontWeight="bold">
                        Qual a sua experiência com seguros?
                      </FormLabel>
                      <RadioGroupControl name="experience" w="45rem" mb="2rem">
                        <Stack>
                          <Radio value="0">Não tenho experiência</Radio>
                          <Radio value="1">De 1 a 5 anos</Radio>
                          <Radio value="2">De 6 a 10 anos</Radio>
                          <Radio value="3">Mais de 10 anos</Radio>
                        </Stack>
                      </RadioGroupControl>
                    </Box>
                    <Box>
                      <FormLabel fontWeight="bold">
                        Conte para nós como é o seu local de trabalho.
                      </FormLabel>
                      <RadioGroupControl name="workPlace" mb="2rem">
                        <Stack>
                          <Radio value="0">
                            Home Office - atendimento à distância
                          </Radio>
                          <Radio value="1">Sede física exclusiva</Radio>
                          <Radio value="2">
                            Escritório compartilhado com outros corretores e/ou
                            em sede de Seguradora
                          </Radio>
                          <Radio value="3">
                            Espaço <i>Coworking</i>
                          </Radio>
                        </Stack>
                      </RadioGroupControl>
                    </Box>
                  </InputsContainer>
                  <InputsContainer w="25rem">
                    <FormLabel fontWeight="bold">
                      Qual é sua estimativa de renda mensal
                    </FormLabel>
                    <FormSelect
                      name="estimatedIncome"
                      label="Estimativa de Renda Mensal"
                      onChange={() => clearExactIncome(values, setFieldValue)}
                      selectProps={{
                        placeholder: 'Estimativa de Renda'
                      }}
                      _placeholder={{ color: 'gray.200' }}
                    >
                      <option value="R$ 0,01 ~ R$ 1.000">
                        R$ 0,01 ~ R$ 1.000
                      </option>
                      <option value="R$ 1.000,01 ~ R$ 3.000">
                        R$ 1.000,01 ~ R$ 3.000
                      </option>
                      <option value="R$ 3.000,01 ~ R$ 6.000">
                        R$ 3.000,01 ~ R$ 6.000
                      </option>
                      <option value="R$ 6.000,01 ou superior">
                        Superior a R$ 6.000,01
                      </option>
                    </FormSelect>
                    {values.estimatedIncome === 'R$ 6.000,01 ou superior' && (
                      <FormMaskedInput
                        mask="currency"
                        label=""
                        name="exactIncome"
                        placeholder="Digite o valor exato da sua renda"
                        required
                      />
                    )}

                    {/* <FormMaskedInput
                      mask="currency"
                      label="Estimativa de Renda Mensal"
                      name="estimatedIncome"
                    /> */}
                    <Box>
                      <FormLabel fontWeight="bold">
                        Cite as 3 principais Seguradoras e/ou Operadoras que
                        você trabalha:
                      </FormLabel>
                      <FormSelect
                        name="firstInsurer"
                        mb="1rem"
                        _placeholder={{ color: 'green.200' }}
                        color="green.200"
                      >
                        <option value="">
                          SELECIONE A SEGURADORA/OPERADORA
                        </option>
                        <option value="AINDA NÃO TRABALHO COM SEGURADORAS">
                          AINDA NÃO TRABALHO COM SEGURADORAS
                        </option>
                        {insurers
                          .filter(
                            (insurer) =>
                              insurer !== values.secondInsurer &&
                              insurer !== values.thirdInsurer
                          )
                          .map((insurer) => (
                            <option key={insurer} value={insurer}>
                              {insurer}
                            </option>
                          ))}
                      </FormSelect>

                      <FormSelect
                        name="secondInsurer"
                        mb="1rem"
                        isDisabled={
                          values.firstInsurer.length === 0 ||
                          values.firstInsurer ===
                            'AINDA NÃO TRABALHO COM SEGURADORAS'
                        }
                      >
                        <option value="">
                          SELECIONE A SEGURADORA/OPERADORA
                        </option>
                        {insurers
                          .filter(
                            (insurer) =>
                              insurer !== values.firstInsurer &&
                              insurer !== values.thirdInsurer
                          )
                          .map((insurer) => (
                            <option key={insurer} value={insurer}>
                              {insurer}
                            </option>
                          ))}
                      </FormSelect>

                      <FormSelect
                        name="thirdInsurer"
                        mb="1rem"
                        isDisabled={values.secondInsurer.length === 0}
                      >
                        <option value="">
                          SELECIONE A SEGURADORA/OPERADORA
                        </option>
                        {insurers
                          .filter(
                            (insurer) =>
                              insurer !== values.firstInsurer &&
                              insurer !== values.secondInsurer
                          )
                          .map((insurer) => (
                            <option key={insurer} value={insurer}>
                              {insurer}
                            </option>
                          ))}
                      </FormSelect>

                      <FormInput
                        name="otherInsurer"
                        placeholder="Outros/Ainda não trabalho com operadoras"
                        isDisabled={
                          values.firstInsurer.length === 0 ||
                          values.firstInsurer ===
                            'AINDA NÃO TRABALHO COM SEGURADORAS'
                        }
                      />
                    </Box>
                    <Box>
                      <FormLabel fontWeight="bold">
                        Informe a estimativa de venda anual de produção da sua
                        corretora para os seguintes produtos, considerando todas
                        as seguradoras e operadoras em que você já está
                        cadastrado e/ou irá se cadastrar:
                      </FormLabel>

                      <Text>Saúde</Text>
                      <FormSelect
                        name="healthValue"
                        mb="1rem"
                        selectProps={{
                          placeholder: 'Selecione o valor'
                        }}
                      >
                        <option value="2">Mais de R$100 mil</option>
                        <option value="1">Até R$100 mil</option>
                        <option value="0">Não se aplica</option>
                      </FormSelect>

                      <Text>Odonto</Text>
                      <FormSelect
                        name="dentistryValue"
                        mb="1rem"
                        selectProps={{
                          placeholder: 'Selecione o valor'
                        }}
                      >
                        <option value="2">Mais de R$100 mil</option>
                        <option value="1">Até R$100 mil</option>
                        <option value="0">Não se aplica</option>
                      </FormSelect>

                      <Text>Seguro Viagem</Text>
                      <FormSelect
                        name="travelInsuranceValue"
                        mb="1rem"
                        selectProps={{
                          placeholder: 'Selecione o valor'
                        }}
                      >
                        <option value="2">Mais de R$100 mil</option>
                        <option value="1">Até R$100 mil</option>
                        <option value="0">Não se aplica</option>
                      </FormSelect>

                      <Text>Seguro de Vida</Text>
                      <FormSelect
                        name="lifeInsuranceValue"
                        mb="1rem"
                        selectProps={{
                          placeholder: 'Selecione o valor'
                        }}
                      >
                        <option value="2">Mais de R$100 mil</option>
                        <option value="1">Até R$100 mil</option>
                        <option value="0">Não se aplica</option>
                      </FormSelect>

                      <Text>Previdência Privada</Text>
                      <FormSelect
                        name="privateInsuranceValue"
                        mb="1rem"
                        selectProps={{
                          placeholder: 'Selecione o valor'
                        }}
                      >
                        <option value="2">Mais de R$100 mil</option>
                        <option value="1">Até R$100 mil</option>
                        <option value="0">Não se aplica</option>
                      </FormSelect>

                      <Text>Investimentos</Text>
                      <FormSelect
                        name="investmentsValue"
                        mb="1rem"
                        selectProps={{
                          placeholder: 'Selecione o valor'
                        }}
                      >
                        <option value="2">Mais de R$100 mil</option>
                        <option value="1">Até R$100 mil</option>
                        <option value="0">Não se aplica</option>
                      </FormSelect>
                    </Box>
                  </InputsContainer>
                </Stack>
              </Flex>
            </MainContent>
            <FooterContainer>
              <HStack
                h="100%"
                spacing={{ base: '0', md: '10', lg: '10' }}
                flexWrap="wrap"
                justify="center"
                textAlign="center"
              >
                <CustomButton variant="outline" onClick={() => setStepState(1)}>
                  VOLTAR
                </CustomButton>
                <CustomButton
                  type="submit"
                  isLoading={loading}
                  onClick={() => console.log(values)}
                >
                  AVANÇAR
                </CustomButton>
              </HStack>
            </FooterContainer>
          </Flex>
        )}
      </Formik>
    </>
  );
};
