import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type Props = {
  description: string;
  isStep: boolean;
  complete: boolean;
  last?: boolean;
  step?: string;
};

export const StepItem = ({
  description,
  complete,
  isStep,
  last,
  step
}: Props): JSX.Element => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  const [borderColor, setBorderColor] = useState('');

  useEffect(() => {
    setBorderColor(handleBorderColor(isStep, complete));
  }, [isStep, complete]);

  const handleBorderColor = (isStep: boolean, complete: boolean) => {
    if (complete) return '#1B6F2C';
    else if (isStep) return '#001E64';
    else return '#bdbdbe';
  };

  return (
    <Flex gap=".4rem">
      <Flex direction="column" justify="center" align="center">
        <Flex
          w="2rem"
          h="2rem"
          justify="center"
          align="center"
          borderRadius="50%"
          bg={!complete ? '#fff' : '#1B6F2C'}
          border={'.25rem solid' + borderColor}
        >
          {complete ? (
            <Image src="/images/complete.svg" w="1.2rem" />
          ) : (
            <Text fontWeight="bold" color={borderColor}>
              {step}
            </Text>
          )}
        </Flex>
        {!last && (
          <Box
            w="2px"
            h="4rem"
            border={complete ? '2px solid #1B6F2C' : '2px solid #bdbdbe'}
          ></Box>
        )}
      </Flex>
      <Box mt="2px">
        <Text
          fontWeight="bold"
          fontSize="1.25rem"
          color={complete ? '#1B6F2C' : '#001E64'}
        >
          {description}
        </Text>
      </Box>
    </Flex>
  );
};
