import {
  Button,
  Flex,
  HStack,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import { PageContent } from '../components/layouts/PageContent';

export const Home = (): JSX.Element => {
  return (
    <PageContent>
      <Flex
        display="flex"
        justify="center"
        align="center"
        w="100%"
        mt="3rem"
        mb="3rem"
      >
        <Stack spacing="6rem" w={{ base: '25rem', md: '55rem', lg: '55rem' }}>
          <Stack w={{ base: '25rem', md: '33rem', lg: '33rem' }} spacing="2rem">
            <Text color="#FD4E00" fontSize="2rem" fontWeight="bold">
              Que bom que você está aqui!
            </Text>
            <Text fontSize="1.2rem">
              Para facilitar o preenchimento do seu cadastro, tenha em mãos os
              documentos listados abaixo.
            </Text>
          </Stack>

          <Stack spacing="1rem">
            <Text fontWeight="bold">Como você quer se cadastrar?</Text>
            <Stack>
              <HStack
                justify="space-between"
                textAlign="center"
                display={{ base: 'column', md: 'flex', lg: 'flex' }}
                w="100%"
                bg="white"
                borderRadius=".8rem"
                boxShadow="0px 4px 10px rgba(50, 50, 50, 0.16)"
                p="2rem 2.8rem"
              >
                <Text fontSize="2rem">
                  <b>Pessoa física</b> (CPF)
                </Text>
                <Link href="/cadastro/fisico">
                  <Button borderRadius="0.4rem" w="13rem" h="3rem">
                    Continuar
                  </Button>
                </Link>
              </HStack>

              <Text fontWeight="bold">
                Documentos necessários para Pessoa Física:
              </Text>
              <UnorderedList pl="2rem">
                <ListItem>Comprovante de Residência</ListItem>
                <ListItem>RG e CPF</ListItem>
                <ListItem>PIS/PASEP/Cadastro INSS</ListItem>
                <ListItem>Habilitação SUSEP da Pessoa Física</ListItem>
                <ListItem>Carta da Assessoria (se houver)</ListItem>
              </UnorderedList>
            </Stack>
            <Stack>
              <HStack
                mt="4rem"
                w="100%"
                justify="space-between"
                textAlign="center"
                display={{ base: 'column', md: 'flex', lg: 'flex' }}
                bg="white"
                borderRadius=".8rem"
                boxShadow="0px 4px 10px rgba(50, 50, 50, 0.16)"
                p="2rem 2.8rem"
              >
                <Text fontSize="2rem">
                  <b>Pessoa jurídica</b> (CNPJ)
                </Text>
                <Link href="/cadastro/juridico">
                  <Button borderRadius="0.4rem" w="13rem" h="3rem">
                    Continuar
                  </Button>
                </Link>
              </HStack>

              <Text fontWeight="bold">
                Documentos necessários para Pessoa Jurídica:
              </Text>
              <UnorderedList pl="2rem">
                <ListItem>Contrato Social e alterações</ListItem>
                <ListItem>Habilitação SUSEP da Pessoa Jurídica</ListItem>
                <ListItem>Inscrição Municipal ou Alvará</ListItem>
                <ListItem>Carta da Assessoria (se houver)</ListItem>
              </UnorderedList>
            </Stack>
          </Stack>
        </Stack>
      </Flex>
      {/* 
      <Modal onClose={onClose} isOpen={isOpen} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          w={{ base: '80%', md: '20%' }}
          boxShadow="0 0 1rem 0.5rem #535353"
        >
          <Box textAlign="center">
            <ModalHeader fontSize="md"></ModalHeader>
            <ModalCloseButton />
            <ModalBody fontSize="sm">
              Dica: Para facilitar o preenchimento do seu cadastro tenha em mãos
              os documentos da sua Empresa, documentos do responsável pela
              Corretora e Habilitação SUSEP
            </ModalBody>
            <ModalFooter justifyContent="space-around">
              <Link href="/signup/legal">
                <Button onClick={onClose}>OK, ENTENDI</Button>
              </Link>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal> */}
    </PageContent>
  );
};
