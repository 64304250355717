import axios from 'axios';

const defaultHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json;charset=UTF-8'
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { ...defaultHeaders }
});
