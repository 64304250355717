import { ChakraProvider, ScaleFade } from '@chakra-ui/react';
import { Header } from './components/navigation/Header';
import { CnpjFormContextProvider } from './contexts/CnpjFormContext';
import { CpfFormContextProvider } from './contexts/CpfFormContext';
import { AppRoutes } from './routes/Routes';
import { theme } from './styles/theme';

export function App() {
  const location = window.location;

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <ScaleFade key={location.pathname} unmountOnExit in>
        <CpfFormContextProvider>
          <CnpjFormContextProvider>
            <AppRoutes />
          </CnpjFormContextProvider>
        </CpfFormContextProvider>
      </ScaleFade>
    </ChakraProvider>
  );
}
