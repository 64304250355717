import {
  Box,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useDisclosure,
  useRadioGroup
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { CustomButton } from '../../../../components/actions/CustomButton';
import { FormRadioCard } from '../../../../components/fields/FormRadioCard';
import { InputsContainer } from '../../../../components/layouts/InputsContainer';
import { MainContent } from '../../../../components/layouts/MainContent';
import { CustomModal } from '../../../../components/widgets/CustomModal';

import { StepTimeLine2 } from '../../../../components/widgets/StepTimeline2/StepTimeLine';
import CnpjFormContext from '../../../../contexts/CnpjFormContext';
import { api } from '../../../../services/api';

export const FifthForm = (): JSX.Element => {
  const { stepState, onboardingId } = useContext(CnpjFormContext);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modal, setModal] = useState({
    title: '',
    content: ''
  });

  const [sent, setSent] = useState(false);

  const [loading, setLoading] = useState(false);

  const [rating, setRating] = useState('');

  const [opinion, setOpinion] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      // console.log({
      //   onboardingId,
      //   rating: +rating,
      //   opinion: opinion
      // });

      await api.post('/signup/cnpj/fourth-step', {
        onboardingId,
        rating: +rating,
        opinion: opinion
      });

      setSent(true);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 429) {
        setModal({
          title: 'Ocorreu um erro',
          content:
            'Parece que muitas tentativas foram feitas, favor tente novamente mais tarde.'
        });

        onOpen();
      }
    }
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const options = ['1', '2', '3', '4', '5'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'rating',
    onChange: setRating
  });

  const group = getRootProps();

  return (
    <>
      <Flex
        flexDir="column"
        as="form"
        flexWrap="wrap"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSubmit={(e) => handleSubmit(e)}
      >
        <MainContent>
          {isWideVersion && <StepTimeLine2 step={stepState} />}

          <Flex
            w="100%"
            align="center"
            flexDir="column"
            flex=".85"
            mt={isWideVersion ? '0' : '5rem'}
          >
            <Stack spacing="2rem">
              <InputsContainer
                w={{ base: '25rem', md: '41rem', lg: '41rem' }}
                textAlign="center"
              >
                <Stack align="center" spacing="2rem">
                  <BsFillCheckCircleFill color="#FF5000" size={74} />
                  <Text color="#FF5000" fontSize="1.5rem" fontWeight="bold">
                    Seu cadastro foi enviado!
                  </Text>
                  <Text>
                    Você finalizou a primeira parte do seu cadastro. Agora fique
                    ligado no seu e-mail que vamos te avisar em que fase está o
                    seu processo.
                  </Text>
                </Stack>
              </InputsContainer>
              <InputsContainer
                w={{ base: '25rem', md: '41rem', lg: '41rem' }}
                textAlign="center"
              >
                <Stack spacing="2rem" align="center">
                  <Text color="#FF5000" fontSize="1.5rem" fontWeight="bold">
                    Pesquisa de satisfação
                  </Text>
                  <Text>
                    Considerando sua experiência até o momento, em uma escala de
                    1 a 5 (sendo 1 para muito insastifeito e 5 para muito
                    satisfeito), qual a sua opinião sobre o nosso processo de
                    cadastro?
                  </Text>
                  <Stack align="center" spacing="2rem">
                    <HStack
                      {...group}
                      spacing="1rem"
                      pointerEvents={!sent ? 'all' : 'none'}
                      opacity={!sent ? '1' : '0.4'}
                    >
                      {options.map((value) => {
                        const radio = getRadioProps({ value });

                        return (
                          <FormRadioCard key={value} {...radio}>
                            {value}
                          </FormRadioCard>
                        );
                      })}
                    </HStack>
                    <Textarea
                      pointerEvents={!sent ? 'all' : 'none'}
                      opacity={!sent ? '1' : '0.4'}
                      borderColor={'gray.800'}
                      w="21.5rem"
                      h="8rem"
                      onChange={(e) => setOpinion(e.target.value)}
                      resize="none"
                      placeholder="Compartilhe sua opinião ou deixe um comentário. (opcional)"
                    />
                    {sent && (
                      <HStack>
                        <Text>Sua avaliação foi enviada. </Text>
                        <BsFillCheckCircleFill color="#43C677" size={25} />
                      </HStack>
                    )}
                  </Stack>
                  <Box>
                    <CustomButton
                      type="submit"
                      isLoading={loading}
                      bg={!sent ? '#ff5000' : '#D2D2D2'}
                      _hover={{
                        backgroundColor: !sent ? 'orange.300' : '#D2D2D2'
                      }}
                      isDisabled={rating.length && !sent ? false : true}
                    >
                      ENVIAR
                    </CustomButton>
                  </Box>
                  <Link href="https://portal.sulamericaseguros.com.br/home.htm">
                    <CustomButton variant="outline">
                      VOLTAR PARA HOME
                    </CustomButton>
                  </Link>
                </Stack>
              </InputsContainer>
            </Stack>
          </Flex>
        </MainContent>
      </Flex>

      <CustomModal
        isOpen={isOpen}
        title={modal.title}
        onClose={onClose}
        content={<Text>{modal.content}</Text>}
      />
    </>
  );
};
