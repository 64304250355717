/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, InputProps, Spinner } from '@chakra-ui/react';
import { Field } from 'formik';
import { InputHTMLAttributes } from 'react';
import MaskedInput from 'react-text-mask';
import CurrencyFormat from 'react-currency-format';

import {
  cnpj,
  cpf,
  cpfCnpj,
  oneDecimal,
  phone,
  postalCode,
  threeDecimal
} from '../../utils/masks';
import { FormControl } from './FormControl';

type FormMaskedInputProps = {
  label: string;
  name: string;
  mask: string;
  placeholder?: string;
  loading?: boolean;
  erroValue?: boolean;
};

type InputControlProps = FormMaskedInputProps &
  InputHTMLAttributes<HTMLInputElement> & { inputProps?: InputProps };

export const FormMaskedInput = ({
  label,
  name,
  mask,
  placeholder,
  loading,
  inputProps,
  ...rest
}: InputControlProps) => {
  const maskRegExp =
    mask === 'cnpj'
      ? cnpj
      : mask === 'cpf'
      ? cpf
      : mask === 'cpfCnpj'
      ? cpfCnpj
      : mask === 'oneDecimal'
      ? oneDecimal
      : mask === 'threeDecimal'
      ? threeDecimal
      : mask === 'postalCode'
      ? postalCode
      : mask === 'phone' && phone;

  return (
    <FormControl name={name} label={label} mb="1.2rem">
      <Flex justify="center" align="center">
        <Field name={name}>
          {({ field }: any) =>
            mask !== 'currency' ? (
              <MaskedInput
                {...field}
                {...rest}
                {...inputProps}
                readOnly={loading}
                mask={maskRegExp}
                guide={false}
                id={name}
                placeholder={placeholder}
                style={{
                  border: 'none',
                  outline: 'none',
                  outlineOffset: '2px',
                  position: 'relative',
                  paddingInlineStart: 0,
                  paddingInlineEnd: 0,
                  marginBottom: '1.5rem',
                  width: '100%',
                  height: '2.5rem',
                  minWidth: 0,
                  borderBottom: loading ? '1px solid #8f8f8f' : '1px solid #000'
                }}
              />
            ) : (
              <CurrencyFormat
                {...field}
                {...rest}
                {...inputProps}
                prefix="R$ "
                thousandSeparator={'.'}
                decimalSeparator={','}
                decimalScale={2}
                id={name}
                placeholder={placeholder}
                readOnly={loading}
                style={{
                  border: 'none',
                  outline: 'none',
                  outlineOffset: '2px',
                  position: 'relative',
                  paddingInlineStart: 0,
                  paddingInlineEnd: 0,
                  marginBottom: '1.5rem',
                  width: '100%',
                  height: '2.5rem',
                  minWidth: 0,
                  borderBottom: loading ? '1px solid #EDF2F7' : '1px solid #000'
                }}
              />
            )
          }
        </Field>

        {loading && (
          <Spinner h=".8rem" w=".8rem" position="absolute" mb="1.5rem" />
        )}
      </Flex>
    </FormControl>
  );
};
