import { useContext } from 'react';
import { PageContent } from '../../../../components/layouts/PageContent';
import CnpjFormContext from '../../../../contexts/CnpjFormContext';
import { FifthForm } from './FifthForm';
import { FirstForm } from './FirstForm';
import { FourthForm } from './FourthForm';
import { SecondForm } from './SecondForm';
import { ThirdForm } from './ThirdForm';

export const CnpjForm = (): JSX.Element => {
  const { stepState } = useContext(CnpjFormContext);

  return (
    <PageContent>
      {stepState === 1 ? (
        <FirstForm />
      ) : stepState === 2 ? (
        <SecondForm />
      ) : stepState === 3 ? (
        <ThirdForm />
      ) : stepState === 4 ? (
        <FourthForm />
      ) : (
        <FifthForm />
      )}
    </PageContent>
  );
};
