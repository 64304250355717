import { Flex, Image, Link, Text } from '@chakra-ui/react';
import { IoMdAlert } from 'react-icons/io';

export const Header = (): JSX.Element => {
  return (
    <>
      <Flex
        pl="4.6rem"
        pr="4.6rem"
        bg="orange.500"
        h="7rem"
        w="100%"
        justify="space-between"
        align="center"
        boxShadow="0px 2px 5px rgba(115, 137, 169, 0.25);"
      >
        <Link href="/">
          <Image src="/images/logoCadastroCorretor.svg" w="9rem" />
        </Link>
        <Image src="/images/peopleIcon.svg" />
      </Flex>

      {/* <Flex mt="1rem" w="100%" justify="center" align="center">
        <Flex
          bg="red.400"
          w="40rem"
          p="1rem"
          borderRadius="10rem"
          color="gray.50"
          justify="center"
          align="center"
          gap="4"
          flexWrap="wrap"
        >
          <IoMdAlert size={40} />
          <Text align="center">
            Serviço Indisponível no momento. Por favor, tente novamente mais
            tarde.
          </Text>
        </Flex>
      </Flex> */}
    </>
  );
};
